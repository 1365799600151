import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import DisabledButton from "../disabledButton";
import GroupVoteSpreadSheetTable from "./groupVoteSpreadSheetTable";
import {
  AddAbbrevationToTimeZone,
  hasStopEventPropagation,
  IsEmailValid,
  isEmptyArray,
  removeDuplicatesFromArray,
} from "../../services/commonUsefulFunctions";
import { getAttendeeEmail, getAttendeeUUID } from "./sharedFunctions";
import { Info, X } from "react-feather";
import { parseEventsWithDefaultTimeZone } from "../../lib/availabilityFunctions";
import SpinnerV2 from "../spinnerV2";

export default function GroupVoteSpreadSheet({
  children,
  onClickSave,
  bookingLink,
  selectedTimeZone,
  isShowingConfirmPage,
  timeZone, // time zone of the group vote spreadsheet
  isMobile,
}) {
  // each attendee should look something like this:
  // {
  //   "uuid": "some-uuid-to-create-or-update",
  //   "name": "Name",
  //   "email": "email@email.com",
  //   "slots": [
  //     {
  //       "start/startDate": "start-value",
  //       "end/endDate": "start-value",
  //       "selected": "true/false",
  //       "comment": "some comment",
  //     }
  //   ]
  // }
  const [editedAttendees, setEditedAttendees] = useState([]);
  const [deletedAttendeesUUID, setDeletedAttendeesUUID] = useState([]);
  const [isShowingTimeZoneChangedBanner, setIsShowingTimeZoneChangedBanner] = useState(timeZone && timeZone !== selectedTimeZone);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isSpinnerRef = useRef(null); // reference to the spinner but will change immediately

  useEffect(() => {
    if (!isShowingTimeZoneChangedBanner && timeZone && timeZone !== selectedTimeZone) {
      setIsShowingTimeZoneChangedBanner(true);
    }
    if (isShowingTimeZoneChangedBanner &&timeZone && timeZone === selectedTimeZone) {
      setIsShowingTimeZoneChangedBanner(false);
    }
  }, [timeZone, selectedTimeZone]);

  useEffect(() => {
    const onCloseTab = (e) => {
      // Cancel the event as per the standard.
      if (!e?.preventDefault) {
        return;
      }
      if (isShowingConfirmPage) {
        // do nothing
      } else if (
        deletedAttendeesUUID.length > 0 ||
        editedAttendees.length > 0
      ) {
        e.preventDefault();
        // Chrome requires returnValue to be set.
        e.returnValue = "";
        alert("Are you sure you want to leave? You have unsaved changes.");
      }
    };
    window.addEventListener("beforeunload", onCloseTab);

    return () => {
      window.removeEventListener("beforeunload", onCloseTab);
    };
  }, [deletedAttendeesUUID, editedAttendees, isShowingConfirmPage]);

  const renderSaveButton = () => {
    if (isEmptyArray(editedAttendees) && isEmptyArray(deletedAttendeesUUID)) {
      // has not updated any attendees
      return <DisabledButton label="Save" />;
    }
    return (
      <div
        className={classNames(
          "custom-button-box width-90px margin-right-10 text-white",
          "blue-button",
          "font-size-12",
          "height-34px",
        )}
        onClick={() => {
          if (isSpinnerRef.current) {
            return;
          }
          isSpinnerRef.current = true;
          setIsSubmitting(true);
          const attendeesWithInvalidEmails = editedAttendees.filter(
            (attendee) => !IsEmailValid(getAttendeeEmail(attendee))
          );
          if (attendeesWithInvalidEmails.length > 0) {
            return;
          }
          const responses = {
            edited_attendees: editedAttendees,
            deleted_attendees: deletedAttendeesUUID,
          };
          onClickSave(responses);
        }}
      >
        {isSubmitting ? <SpinnerV2 variant="smaller" /> : "Save"}
      </div>
    );
  };

  const onDeleteAttendee = (uuid) => {
    setDeletedAttendeesUUID(
      removeDuplicatesFromArray(deletedAttendeesUUID.concat(uuid))
    );
  };

  const onUpdateAttendee = (updatedAttendee) => {
    setEditedAttendees((editedAttendees) => {
      return editedAttendees
        .filter(
          (attendee) =>
            getAttendeeUUID(attendee) !== getAttendeeUUID(updatedAttendee)
        )
        .concat(updatedAttendee);
    });
  };

  const renderScheduler = () => {
    return (
      <div
        className={classNames(
          "group-vote-spreadsheet-container",
          "default-border",
          "rounded-md",
          "mx-7",
          isShowingTimeZoneChangedBanner
            ? "gv-spreadsheet-container-with-banner"
            : ""
        )}
      >
        <GroupVoteSpreadSheetTable
          bookingLink={bookingLink}
          selectedTimeZone={selectedTimeZone}
          onDeleteAttendee={onDeleteAttendee}
          onUpdateAttendee={onUpdateAttendee}
        />
      </div>
    );
  };

  const renderDifferentTimeZoneBanner = () => {
    if (isMobile) {
      return null;
    }

    const getWidth = () => {
      const rows = parseEventsWithDefaultTimeZone(
        bookingLink,
        selectedTimeZone
      );
      // 600 is the attendee section width (left most section)
      // 160 is the width of each time slot
      return 200 + 160 * rows.length;
    };

    return (
      <div
        className="font-size-12 different-time-zone-banner mb-4 mx-7 relative"
        // style={{width: getWidth()}}
      >
        <div className="flex items-center font-weight-500">
          <Info
            size={14}
            color={"rgba(64, 110, 229, 1)"}
            strokeWidth={2}
            className="mr-2"
          />
          Time zone is changed
        </div>

        <div className="margin-left-22px break-normal mt-1">
          <span className="">{`Make sure times that you reference in the notes are in`}</span>
          <span className="mx-1 font-weight-400">
            {AddAbbrevationToTimeZone(timeZone)}
          </span>
          <span className="">{"for consistency."}</span>
        </div>

        <div
          className={classNames(
            "absolute top-2 right-5 soft-border large-blur rounded-full",
            "flex items-center justify-center",
            "hoverable-secondary-text-color",
            "h-6 w-6"
          )}
          onClick={(e) => {
            hasStopEventPropagation(e);
            setIsShowingTimeZoneChangedBanner(false);
          }}
        >
          <X size={14} className="hoverable-secondary-text-color" />
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col items-center w-full h-full">
      <div className="h-full">
        {children}
        {isShowingTimeZoneChangedBanner ? renderDifferentTimeZoneBanner() : null}
        {renderScheduler()}
      </div>
      <div className="w-full flex justify-end my-4 mr-14">
        {renderSaveButton()}
      </div>
    </div>
  );
}
