import {
  createZoomNoteFromUniqueZoom,
  formatRFCPhoneNumber,
  IsEmptyObject,
} from "./commonUsefulFunctions";
import { ZOOM_LOGO_URL } from "./globalVariables";

export function createUniqueZoom(zoomMeeting) {
  const id = getZoomMeetingID(zoomMeeting);
  const videoConferencingOption = {
    entryPointType: "video",
    uri: getZoomJoinURL(zoomMeeting),
    label: getZoomJoinURL(zoomMeeting),
    meetingCode: id,
  };
  if (zoomMeeting?.passcode) {
    videoConferencingOption.passcode = zoomMeeting.passcode;
  }
  if (zoomMeeting?.password) {
    videoConferencingOption.password = zoomMeeting.password;
  }

  const conferenceSolution = {
    key: {
      type: "addOn",
    },
    name: "Zoom Meeting",
    iconUri: ZOOM_LOGO_URL,
  };

  let entryPoints = [videoConferencingOption];

  let { phoneInfo } = getZoomMeetingDetails(zoomMeeting);

  let phoneOption;
  if (phoneInfo) {
    let phoneNumber = formatRFCPhoneNumber(phoneInfo.number, phoneInfo.country);
    phoneOption = {
      entryPointType: "phone",
      label: phoneNumber,
      regionCode: phoneInfo.country,
      uri: `${phoneNumber},,${id}#`,
    };
    entryPoints = entryPoints.concat(phoneOption);
  }

  const zoomConference = {
    entryPoints,
    conferenceSolution: conferenceSolution,
    notes: createZoomNoteFromUniqueZoom(zoomMeeting),
    id,
  };

  return zoomConference;
}

export function getZoomJoinURL(zoomMeeting) {
  return zoomMeeting?.join_url;
}

export function getZoomMeetingID(zoomMeeting) {
  return zoomMeeting?.id;
}

function getZoomMeetingDetails(zoomMeeting) {
  if (IsEmptyObject(zoomMeeting)) {
    return { phoneInfo: null };
  }

  let phoneInfo;
  if (zoomMeeting?.settings?.global_dial_in_numbers?.length > 0) {
    phoneInfo = zoomMeeting.settings.global_dial_in_numbers[0];
  }

  return { phoneInfo };
}
