import React from "react";
import classNames from "classnames";

type SpinnerVariant = "default" | "big" | "small" | "smaller"

const VARIANT_CLASS_NAME_MAP: Record<SpinnerVariant, string> = {
  default: "",
  big: "big-spinner-v2",
  small: "small-spinner-v2",
  smaller: "smaller-spinner-v2",
};

interface SpinnerV2Props {
  variant?: SpinnerVariant
}

/**
 * To center this spinner, place it inside of a flex container with `align-items: center;`
 * and `justify-content: center;`.
 */
export default function SpinnerV2({ variant="default" }: SpinnerV2Props) {
  return (
    <div
      className={classNames("spinner-v2", VARIANT_CLASS_NAME_MAP[variant])}
    >
    </div>
  );
}
