import React, { Component } from "react";
import moment from "moment";
import { withRouter } from "react-router-dom";
import Fetcher from "../services/fetcher";
import { constructRequestURL } from "../services/api";
import {
  constructQueryParams,
  createSlotsForSelection,
  createFreeSlotsBasedOnBusySlots,
  sortTimeSlots,
  SortArrayByDate,
  IsEmailValid,
  AddAbbrevationToTimeZone,
  IsEmptyObject,
  handleError,
  formatRFCPhoneNumber,
  generateBookableSlotsFromObj,
  generateRandomId,
  getAllBusySlots,
  generateFreeSlotsFromBusySlots,
  isEventStartBeforeNow,
  expandedDateAndTimeString,
  checkIfBookableSlotsAreValid,
  CapitalizeFirstletter,
  CapitalizeFirstLetterOfEveryWord,
  createError,
  guessTimeZone,
  convertToTimeZone,
  formatPhoneNumberHumanReadable,
  getPhoneNumberString,
  OpenLink,
  mergeBusySlots,
  getNameAndEmailFromQueryParam,
  getMaestroPersonalOnboardingLinkToken,
  isV2,
  getSlotsPath,
  isOutlookConferencingOption,
  getRegularOnboardingToken,
  getSapphireOnboardingToken,
  getVimcalURLWithAttribution,
  trackEvent,
  shouldRoundToNearest15,
  generateAvailabilityToken,
  getResponseStartTime,
  replaceInviteeNameInTitle,
  shouldReplaceInviteeNameInTitle,
  doesTitleIncludeVariableBlocks,
  addBufferToEvents,
  createUUID,
  isCompanyQuestion,
  getTitle,
  customQuestionsHasCompany,
  getCompanyQuestionIndex,
  replaceVariableBlocksWithValue,
  isEmptyRichText,
  getCustomQuestions,
  isEmptyArray,
  isIOS,
  getConferencing,
  getTimeInAnchorTimeZone,
  isSameEmail,
  getUserEmail,
  isValidTimeZone,
  isNameOrEmailQuestion,
  getMasterAccountReferralCode,
  getEventAttendees,
  getEventStartString,
  createAbbreviationForTimeZone,
  isInIframe,
} from "../services/commonUsefulFunctions";
import ColoredLine from "../components/line";
import MonthlyCalendar from "../components/monthlyCalendar";
import {
  Globe,
  ArrowLeft,
  ChevronLeft,
  ChevronRight,
  X,
  Check,
} from "react-feather";
import Classnames from "classnames";
import {
  VIMCAL_RICH_TEXT_SIGNATURE,
  MOBILE_WIDTH_LIMIT,
  MOBILE_CONTAINER_STYLE,
  BACKEND_ZOOM,
  BACKEND_HANGOUT,
  BACKEND_PHONE,
  BACKEND_WHATS_APP,
  TIME_FORMAT,
  ZOOM_LOGO_URL,
  PHONE_MEETING_ICON,
  BACKEND_PERSONAL,
  NEEDS_ACTION_STATUS,
  ACCEPTED_STATUS,
  UTC_TIME_ZONE,
  ZOOM_PERSONAL_LINK,
  WHATS_APP_ICON,
  BACKEND_CUSTOM_CONFERENCING,
  BACKEND_AVAILABILITY,
  OUTLOOK_PROVIDER,
  DEFAULT_FONT_COLOR,
  COLORED_VIMCAL_LOGO,
  SECOND_IN_MS,
} from "../services/globalVariables";
import GeneralErrorMessage from "../components/generalErrorMessage";
import EventInfoWithIcons from "../components/eventInfoWithIcons";
import RescheduleLinkInfo from "../components/rescheduleLinkInfo";
import AvailabilityLinkEventInfo from "../components/availabilityLinkEventInfo";
import TimeAndTimeZoneText from "../components/timeAndTimeZoneText";
import LoadingScreen from "../components/loadingScreen";
import _ from "underscore";
import {
  format,
  startOfMinute,
  subMinutes,
  addHours,
  differenceInHours,
  parseISO,
  addMinutes,
} from "date-fns";
import * as Sentry from "@sentry/browser";
import GroupVotePreview from "../components/groupVote/groupVotePreview";
import classNames from "classnames";
import {
  convertSlotsIntoISOString,
  createKeyFromSlotISOString,
  getNonExpiredSelectedSlotsWithDefaultTimeZone,
} from "../lib/availabilityFunctions";
import PhoneNumber from "awesome-phonenumber";
import AdditionalGuestsInput from "../components/additionalGuestsInput";
import { createUniqueZoomDescription } from "../lib/conferencing";
import HoverableLogo from "../components/hoverableLogo";
import Spinner from "../components/spinner";
import PreloadResources from "../components/preloadResources";
import GoogleLoginButton from "../components/googleLoginButton";
import OutlookLoginButton from "../components/outlookLoginButton";
import { TRY_VIMCAL_COPY } from "../lib/copy";
import DownloadIOSButton from "../components/downloadIOSButton";
import SlotsDemoGif from "../components/slotsDemoGif";
import { GIF_GROUPS, getGifGroup } from "../lib/experimentUtils";
import CustomSelect from "../components/customSelect";
import { isEventSlotAllDayEvent } from "../lib/rbcFunctions";
import { customMenuStyle } from "../lib/reactSelectFunctions";
import { getAttendeeEmail } from "../components/groupVoteSpreadSheet/sharedFunctions";
import GroupVoteSpreadSheet from "../components/groupVoteSpreadSheet";
import EventModalPopup from "../components/eventModalPopup";
import { createUniqueZoom } from "../services/zoomFunctions";

const PAST_DAY = {
  title: "Expired link",
  subText:
    "Looks like the time you selected is in the past. Please try another one.",
};
const SELECTED_DAY_UNAVAILABLE = {
  title: "Selected day is no longer available",
  subText:
    "Looks like the date you selected has all its slots booked already. Please try another one.",
};
const TIME_BLOCK_FORMAT = "EEEE MMMM d, yyyy";

class AvailabilityLink extends Component {
  constructor(props) {
    super(props);

    const index = this.getIndex();

    this._isSubmitting = false; // can't use setState because it's asynchrnonous so people could double click and send in two request
    this._meetingToken = null; // track the meeting schedule and cancel token

    const { name, email } = getNameAndEmailFromQueryParam();
    const guessedTimeZone = guessTimeZone();
    
    this.state = {
      title: null,
      conferencing: null,
      location: null,
      duration: null,
      freeBusy: null,
      user: null,
      areAllSlotsBusy: false,
      expired: false,
      error: false,
      userCalendarId: null,
      daySlots: {},
      selectedDay: null,
      selectedTime: null,
      hoverDay: null,
      onClickedConfirmTime: false,
      hasCapitalizedFirstLetter: false,
      showSentConfirmationEmail: false,
      nameHasIssue: false,
      emailHasIssue: false,
      shouldDisplayRequestAccessEmailSent: false,
      shouldShowMobileSelectTime: false,
      inputName: name || "",
      inputEmail: email || "",
      currentDayIndex: [],
      isMobile: window.innerWidth <= MOBILE_WIDTH_LIMIT,
      token: this.getToken(props),
      errorWarning: null,
      availabilityEvents: null,
      zoomLink: null,
      timeSlotsInfo: null,
      host_time_zone: guessedTimeZone,
      doesNotHaveIndex: index === null || index === undefined,
      appointmentToken: props.match.params.appointmentToken,
      refreshCount: 0,
      regionCodeOptions: this.createRegionCode(),
      buffer_from_now: 0,
      index,
      showExpandedAttendees: false,
      additionalGuests: [],
      guestInputText: "",
      is_scheduling_for_exec: false,
      backendAppointmentToken: null,
      initialLoadTime: this.getNow(),
      isDeviceIOS: isIOS(),
      selectedTimeZone: guessedTimeZone,
      hideLogo: isInIframe(),
      isTimeNoLongerAvailableModalOpen: false,
    };

    this.fetchAvailability = this.fetchAvailability.bind(this);
    this.fetchPersonalLink = this.fetchPersonalLink.bind(this);
    this.bookAvailabiity = this.bookAvailabiity.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.onMouseEnterDaySlot = this.onMouseEnterDaySlot.bind(this);
    this.onClickConfirmOnTime = this.onClickConfirmOnTime.bind(this);
    this.onClickBackFromConfirmNameAndEmail =
      this.onClickBackFromConfirmNameAndEmail.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onClickSchedule = this.onClickSchedule.bind(this);
    this.onChangeRequestEarlyAccessEmail =
      this.onChangeRequestEarlyAccessEmail.bind(this);
    this.onClickRequestEarlyAccess = this.onClickRequestEarlyAccess.bind(this);
    this.handleWindowSizeChange = this.handleWindowSizeChange.bind(this);
    this.onClickBackFromSelectTime = this.onClickBackFromSelectTime.bind(this);
    this.goBackADay = this.goBackADay.bind(this);
    this.goForwardADay = this.goForwardADay.bind(this);
    this.closeWarningMessage = this.closeWarningMessage.bind(this);
    this.onClickDaySlot = this.onClickDaySlot.bind(this);
    this.onClickReschedule = this.onClickReschedule.bind(this);
    this.reschedulePersonalLink = this.reschedulePersonalLink.bind(this);
    this.bookPersonalLink = this.bookPersonalLink.bind(this);
    this.updateCustomQuestion = this.updateCustomQuestion.bind(this);
    this.onChangeRegionCode = this.onChangeRegionCode.bind(this);
    this.onClickGoToVimcal = this.onClickGoToVimcal.bind(this);
    this.setAdditionalGuests = this.setAdditionalGuests.bind(this);
    this.setGuestInputText = this.setGuestInputText.bind(this);
    this.submitGroupVoteSpreadsheet =
      this.submitGroupVoteSpreadsheet.bind(this);
    this.onSelectTimeZone = this.onSelectTimeZone.bind(this);

    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  componentDidMount() {
    this._isMounted = true;
    this.determineInitialDataFetch();
  }

  componentWillUnmount() {
    this._isMounted = false;

    clearTimeout(this.disappearingErrorMessageTimer);
    this.disappearingErrorMessageTimer = null;

    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  createRegionCode() {
    let possibleRegionCode = PhoneNumber.getSupportedRegionCodes();

    return possibleRegionCode.map((c) => {
      return { value: c, label: this.createRegionCodeText(c) };
    });
  }

  createRegionCodeText(regionCode) {
    return `${regionCode} (+${PhoneNumber.getCountryCodeForRegionCode(
      regionCode
    )})`;
  }

  onChangeRegionCode(data) {
    this.setState({ phone_region_code: data.value });
  }

  render() {
    const { isMobile } = this.state;
    // why we need to use % for mobile and vh for web
    //https://stackoverflow.com/questions/75222493/bottom-navigation-of-webpage-hidden-behind-safaris-url-bar-swipe-up-bar-ipho
    const outerWrapper = classNames(
      "availability-link-background",
      isMobile
        ? "mobile-wrapper absolute top-0 bottom-0 left-0 right-0"
        : "web-wrapper h-screen overflow-y-auto"
    );

    if (this.state.isGroupVote && !this.state.onClickedConfirmTime) {
      const shouldHideLogo = isMobile || this.isGroupVoteSpreadsheet() || this.state.hideLogo;
      return (
        <div
          className={classNames(
            outerWrapper,
            (this.shouldDisplayWelcomeInviteeBanner() && !isMobile) || !isMobile
              ? "flex-col"
              : ""
          )}
        >
          {isMobile ? null : this.renderGreeting()}

          <div
            className={classNames(
              "default-container-border-background max-h-screen max-width-screen rounded-md",
              isMobile ? "overflow-x-hidden" : "",
              this.isGroupVoteSpreadsheet ? "" : "overflow-y-auto"
            )}
            style={
              isMobile ? MOBILE_CONTAINER_STYLE : this.determineContainerStyle()
            }
          >
            {this.determineContent()}
            <PreloadResources />
          </div>

          {shouldHideLogo ? null : <HoverableLogo />}
        </div>
      );
    } else if (
      !this.isConditionalState() &&
      IsEmptyObject(this.state.daySlots)
    ) {
      return (
        <div className={outerWrapper}>
          <div
            className="availability-link-container"
            style={
              this.state.isMobile
                ? MOBILE_CONTAINER_STYLE
                : { width: 500, height: 700 }
            }
          >
            <LoadingScreen />
          </div>
        </div>
      );
    } else {
      const { isMobile } = this.state;
      const shouldHideLogo = this.state.isMobile ||
        this.state.error ||
        this.state.hideLogo ||
        this.state.showSentConfirmationEmail;
      return (
        <div
          className={classNames(
            outerWrapper,
            (this.shouldDisplayWelcomeInviteeBanner() && !isMobile) || !isMobile
              ? "flex-col"
              : ""
          )}
        >
          {this.state.isMobile ? null : this.renderGreeting()}
          <div
            className="availability-link-container"
            style={
              this.state.isMobile
                ? MOBILE_CONTAINER_STYLE
                : this.determineContainerStyle()
            }
          >
            {this.determineContent()}
            <PreloadResources />
          </div>

          {shouldHideLogo ? null : (
            <HoverableLogo />
          )}
        </div>
      );
    }
  }

  renderGreeting() {
    if (!this.shouldDisplayWelcomeInviteeBanner()) {
      return null;
    }

    let firstName = this.state.invitee_full_name.split(" ")[0];
    let userFirstName = this.state.user.first_name;
    let greeting = `Hi ${CapitalizeFirstletter(
      firstName
    )}! Please select the best time for you to meet with ${userFirstName}.`;
    if (!userFirstName) {
      greeting = `Hi ${CapitalizeFirstletter(
        firstName
      )}! Please select the best time for you to meet.`;
    }

    return <div className="availability-custom-name-banner">{greeting}</div>;
  }

  renderShowError() {
    return <GeneralErrorMessage message={"An error has occurred!"} />;
  }

  renderShowCalendarsUnavailable() {
    return (
      <GeneralErrorMessage
        title={"This calendar is currently unavailable"}
        titleFontSize={20}
        titleStyle={{fontSize: 20, fontWeight: 500, textAlign: "center"}}
        containerClassName={"flex items-center flex-col w-full margin-top-32-percent"}
        user={this.state.user}
        userNameClassName={"font-weight-500 font-size-20 mb-2"}
      >
        <span className="flex items-center justify-center gap-1 mt-4 flex-wrap text-center">
          <span>If you are the owner of this account, you can</span>
          <span 
            className="text-color-link cursor-pointer" 
            onClick={() => {
              OpenLink("https://calendar.vimcal.com/login");
            }}
          >
            log in
          </span>
          <span>to find out more.</span>
        </span>
      </GeneralErrorMessage>
    );
  }

  renderShowExpired() {
    return (
      <GeneralErrorMessage message={"The link you clicked on has expired!"} />
    );
  }

  renderNoAvailabilityError() {
    return (
      <GeneralErrorMessage
        titleStyle={{ fontSize: 24, marginBottom: 5, textAlign: "center" }}
        title={"This calendar is currently not available."}
      >
        <div
          style={{
            fontSize: 16,
            width: 400,
            marginTop: 20,
            textAlign: "center",
          }}
        >
          If you are the owner of the account, please{" "}
          <a href="https://calendar.vimcal.com">login</a>
          &nbsp;to find out more.
        </div>
      </GeneralErrorMessage>
    );
  }

  renderNotFound() {
    return (
      <GeneralErrorMessage
        title={"Link not found"}
        message={"This link may have been modified or deleted"}
      />
    );
  }

  renderOneLinerMessage(message) {
    return (
      <div className="availability-error-container">
        <div style={{ fontSize: 18, fontWeight: 300, marginBottom: 70 }}>
          {message}
        </div>
      </div>
    );
  }

  renderConfirmationBottomTryVimcal() {
    const { isMobile, isDeviceIOS } = this.state;
    if (isMobile) {
      if (isDeviceIOS) {
        return (
          <>
            <HoverableLogo
              containerClassName="mt-8 primary-text-fill-important"
              hideTagLine={true}
            />

            <div className="font-size-12 mt-5 mb-4 text-center">
              {TRY_VIMCAL_COPY}
            </div>
            <div className="flex items-center gap-3">
              <DownloadIOSButton />
            </div>
          </>
        );
      }
      return (
        <>
          <HoverableLogo
            containerClassName="mt-8 primary-text-fill-important"
            hideTagLine={true}
          />

          <div className="font-size-12 mt-5 mb-4 text-center">
            {TRY_VIMCAL_COPY}
          </div>
          <div
            className="confirm-button select-none mt-2"
            onClick={this.onClickGoToVimcal}
            style={{ width: 124, height: 42 }}
          >
            Try it free
          </div>
        </>
      );
    }
    const gifBucket = this.getGIFGroup();
    const shouldShowGIF = this.shouldShowGIF(gifBucket);
    return (
      <>
        <HoverableLogo
          containerClassName="mt-14 primary-text-fill-important"
          hideTagLine={true}
        />

        <div className="font-size-12 mt-5 mb-4 text-center">
          {TRY_VIMCAL_COPY}
        </div>
        <div className="flex items-center gap-3">
          <GoogleLoginButton bucket={gifBucket} />
          <OutlookLoginButton bucket={gifBucket} />
        </div>
        {shouldShowGIF ? <SlotsDemoGif /> : null}
      </>
    );
  }

  getGIFGroup() {
    const { inputName, inputEmail } = this.state;
    return getGifGroup({
      name: inputName,
      email: inputEmail,
    });
  }

  shouldShowGIF(bucket) {
    return bucket === GIF_GROUPS.SHOW_GIF;
  }

  renderConfirmationGroupVote() {
    return (
      <div
        className="position-relative entire-availability-container-size"
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          overflowY: "auto",
          width: this.state.isMobile ? "90%" : null,
        }}
      >
        <div style={{ fontSize: 18, fontWeight: 400, marginTop: 60 }}>
          Confirmed!
        </div>

        {this.state.user && (
          <div
            className="text-center"
            style={{
              fontSize: 14,
              fontWeight: 300,
              marginTop: 3,
            }}
          >
            {`You have submitted your vote`}
          </div>
        )}

        <ColoredLine
          style={{
            marginTop: 20,
            marginBottom: 20,
            width: this.state.isMobile ? "100%" : "80%",
          }}
        />

        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <div style={{ width: "75%" }}>
            <EventInfoWithIcons
              info={this.state}
              selectedTimeZone={this.getSelectedTimeZone()}
              shouldShowTimeZoneDropdown={false}
            />
          </div>
        </div>

        <ColoredLine
          style={{
            marginTop: 20,
            marginBottom: 40,
            width: this.state.isMobile ? "100%" : "80%",
          }}
        />

        {this.renderConfirmationBottomTryVimcal()}
      </div>
    );
  }

  renderColoredVimcalLogo() {
    return (
      <div
        className="flex flex-col items-center mt-8 cursor-pointer"
        onClick={this.onClickGoToVimcal}
      >
        <img alt="" width="100px" src={COLORED_VIMCAL_LOGO} />
        <div className="secondary-text-color font-size-14 mt-2">
          The world's fastest calendar
        </div>
      </div>
    );
  }

  renderMobileConfirmationEmail() {
    return (
      <div
        className={classNames(
          "position-relative entire-availability-container-size",
          "flex items-center flex-col overflow-y-scroll overflow-x-hidden"
        )}
        style={{ width: "90%" }}
      >
        <div
          className="rounded-full w-7 h-7 flex items-center justify-center mt-4"
          style={{ backgroundColor: "#7AD7B2" }}
        >
          <Check size={14} color="white" />
        </div>

        <div className="font-size-16 mt-3 secondary-text-color">{`You are scheduled with`}</div>
        <div className="font-size-20 font-weight-400 mt-2">
          {this.getFullName() || getUserEmail(this.state.user)}
        </div>

        <EventInfoWithIcons
          info={this.state}
          additionalClassName={"confirmed-page-event-detail-width mt-2"}
          containerClassName={"mb-5 mt-5 flex items-center flex-col"}
          selectedTimeZone={this.getSelectedTimeZone()}
          shouldShowTimeZoneDropdown={false}
        />

        <ColoredLine width="90%" inputClassName="mt-8" />
        {this.renderConfirmationBottomTryVimcal()}
      </div>
    );
  }

  renderConfirmationEmailWithLoginButtons() {
    return (
      <div
        className={classNames(
          "position-relative entire-availability-container-size",
          "flex items-center flex-col overflow-y-scroll overflow-x-hidden"
        )}
      >
        <div
          className="rounded-full w-7 h-7 flex items-center justify-center mt-8"
          style={{ backgroundColor: "#7AD7B2" }}
        >
          <Check size={14} color="white" />
        </div>

        <div className="font-size-16 mt-3 mb-8 font-weight-400">{`You are scheduled with ${
          this.getFullName() || getUserEmail(this.state.user)
        }`}</div>

        <ColoredLine width="90%" />

        <EventInfoWithIcons
          info={this.state}
          additionalClassName={"confirmed-page-event-detail-width mt-2"}
          containerClassName={"mt-6 mb-2 flex items-center flex-col"}
          selectedTimeZone={this.getSelectedTimeZone()}
          shouldShowTimeZoneDropdown={false}
        />

        <ColoredLine width="90%" inputClassName="mt-8" />

        {this.renderConfirmationBottomTryVimcal()}
      </div>
    );
  }

  renderRequestAccess() {
    if (!this.state.shouldDisplayRequestAccessEmailSent) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignItems: "center",
          }}
        >
          <div
            className="text-center"
            style={{ fontSize: 16, fontWeight: 300 }}
          >
            The world's fastest calendar. Designed for a remote world.
          </div>

          <div
            className="start-free-trial-button"
            onClick={this.onClickGoToVimcal}
          >
            Check Us Out
          </div>
        </div>
      );
    }
  }

  onClickGoToVimcal() {
    OpenLink(getVimcalURLWithAttribution());
  }

  renderMobileRequestAccessView() {
    return (
      <div
        style={{
          marginTop: this.state.isMobile ? 16 : 25,
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <input
          className="request-early-access-input"
          value={this.state.inputEmail}
          onChange={this.onChangeRequestEarlyAccessEmail}
          style={{ width: "100%" }}
        />

        <div
          className="request-early-access"
          onClick={this.onClickRequestEarlyAccess}
          style={{ marginTop: 10 }}
        >
          Request early access
        </div>
      </div>
    );
  }

  renderDesktopRequestAccessView() {
    return (
      <div
        className="display-flex-flex-direction-row"
        style={{ marginTop: this.state.isMobile ? 16 : 25, width: "80%" }}
      >
        <input
          className="request-early-access-input"
          value={this.state.inputEmail}
          onChange={this.onChangeRequestEarlyAccessEmail}
        />

        <div
          className="request-early-access"
          onClick={this.onClickRequestEarlyAccess}
        >
          Request early access
        </div>
      </div>
    );
  }

  renderBackButton(onClickHandler) {
    const { isMobile } = this.state;
    return (
      <div
        className="availability-back-button-container"
        onClick={onClickHandler}
      >
        <div className="availability-back-button">
          <ArrowLeft
            size={16}
            className="hoverable-secondary-text-color"
            color={isMobile ? DEFAULT_FONT_COLOR : undefined}
          />
        </div>
      </div>
    );
  }

  renderConfirmReschedule() {
    let newStart = this.state.selectedTime.start;
    let newEnd = this.state.selectedTime.end;

    let event = {
      eventStart: newStart,
      eventEnd: newEnd,
    };

    let newTimeText = expandedDateAndTimeString(event);

    const determineButtonLabel = () => {
      if (this.state.isSubmitting) {
        return <Spinner useSmallSpinner={true} className="absolute -top-4" />;
      }

      return "Reschedule Event";
    };

    return (
      <div
        className="availability-enter-name-email-container position-relative"
        style={this.state.isMobile ? { marginTop: 15 } : {}}
      >
        <div className="width-100-percent">
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              fontSize: 18,
              fontWeight: 400,
            }}
          >
            Confirm Details
          </div>
        </div>

        <div className="width-100-percent">
          <TimeAndTimeZoneText time={newTimeText} isNewTime={true} selectedTimeZone={this.getSelectedTimeZone()} />
        </div>

        <div
          className="availability-schedule-event-button relative"
          onClick={this.onClickReschedule}
        >
          {determineButtonLabel()}
        </div>
      </div>
    );
  }

  renderEnterNameAndEmail() {
    const { isMobile } = this.state;
    return (
      <div
        className="availability-enter-name-email-container position-relative"
        style={isMobile ? { marginTop: 15 } : {}}
      >
        <div
          className={classNames(
            "width-full",
            isMobile
              ? "flex justify-center fonts-size-16 font-weight-400"
              : "flex justify-start font-size-14 font-weight-400"
          )}
        >
          Meeting Details
        </div>

        <div className="w-full overflow-y-auto pr-5 overflow-x-hidden">
          <div
            className={classNames(
              "w-full font-size-14",
              isMobile ? "mt-4" : "pt-5 mt-4"
            )}
          >
            <div className="mb-2">Name *</div>

            <input
              className="availability-input"
              autoFocus={!this.state.isGroupVote}
              value={this.state.inputName}
              onChange={this.onChangeName}
            />

            {this.state.nameHasIssue &&
              this.renderWarning("*Please enter your name")}
          </div>

          <div className="mt-5 w-full">
            <div className="mb-2 font-size-14">Email *</div>

            <input
              className="availability-input"
              value={this.state.inputEmail}
              autoFocus={!!this.state.isGroupVote}
              onChange={this.onChangeEmail}
            />

            {this.state.emailHasIssue &&
              this.renderWarning("*Please enter a valid email")}
          </div>

          {this.renderCompanyQuestion()}

          {this.renderCustomQuestions()}

          {this.renderAddAttendees()}
        </div>
        {isMobile ? null : this.renderSaveButton()}
      </div>
    );
  }

  renderCompanyQuestion() {
    const { filteredQuestions } = this.state;
    if (!customQuestionsHasCompany(filteredQuestions)) {
      return null;
    }
    const questionIndex = getCompanyQuestionIndex(filteredQuestions);
    if (questionIndex < 0) {
      return null;
    }

    return (
      <div className="mt-5 w-full">
        <div className="mb-2 font-size-14">Company *</div>
        <input
          className="availability-input"
          value={this.state.filteredQuestions[questionIndex]?.answer || ""}
          onChange={(e) =>
            this.updateCustomQuestion({ answer: e.target.value }, questionIndex)
          }
        />
        {this.questionHasError(filteredQuestions[questionIndex])
          ? this.renderWarning("*This field cannot be empty")
          : null}
      </div>
    );
  }

  updateCustomQuestion(updates, customQuestionIndex) {
    const customQuestionsCopy = [...this.state.filteredQuestions];
    const customQuestion = {
      ...this.state.filteredQuestions[customQuestionIndex],
      ...updates,
    };
    customQuestionsCopy[customQuestionIndex] = customQuestion;
    this.setState({ filteredQuestions: customQuestionsCopy });
  }

  questionHasError(question) {
    return (
      this.state?.customQuestionsHasIssue &&
      question.required &&
      !question?.answer
    );
  }

  renderCustomQuestions() {
    const {
      filteredQuestions
    } = this.state;
    if (
      filteredQuestions &&
      !IsEmptyObject(filteredQuestions)
    ) {
      return filteredQuestions.map((question, questionIndex) => {
        // We expect that the first two questions are name and email which are already displayed above
        if (isCompanyQuestion(question)) {
          // show question above
          return null;
        }
        switch (question.type) {
          case "multiline":
            return (
              <div
                className="mt-5 w-full font-size-14"
                key={`custom-questions-multi-line-${questionIndex}`}
              >
                <div className="mb-2">
                  {question.description}
                  {question.required ? " *" : ""}
                </div>

                <textarea
                  className="multiline-input"
                  value={
                    filteredQuestions[questionIndex]?.answer || ""
                  }
                  onChange={(e) =>
                    this.updateCustomQuestion(
                      { answer: e.target.value },
                      questionIndex
                    )
                  }
                />
                {this.questionHasError(question)
                  ? this.renderWarning("*This field cannot be empty")
                  : null}
              </div>
            );
          case "phoneNumber":
            return (
              // Add margin bottom if phone number is last question to make space for international code selector
              <div
                className="mt-5 w-full font-size-14"
                key={`custom-questions-phone-number-${questionIndex}`}
                style={{
                  marginBottom:
                    questionIndex === this.state.filteredQuestions.length - 1
                      ? "120px"
                      : "0px",
                }}
              >
                <div className="mb-2">
                  {question.description}
                  {question.required ? " *" : ""}
                </div>
                <div className="display-flex-flex-direction-row justify-between	">
                  <CustomSelect
                    value={{
                      label: this.createRegionCodeText(
                        question?.regionCode || "US"
                      ),
                      value: question?.regionCode || "US",
                    }}
                    onChange={(data) =>
                      this.updateCustomQuestion(
                        { regionCode: data.value },
                        questionIndex
                      )
                    }
                    options={this.state.regionCodeOptions}
                    menuListMaxHeight={144}
                    menuListStyle={customMenuStyle({ marginLeft: "2px" })}
                  />
                  <input
                    className="availability-input"
                    style={{ paddingLeft: 10, marginLeft: "10px" }}
                    value={
                      this.state.filteredQuestions[questionIndex]?.answer || ""
                    }
                    onChange={(e) =>
                      this.updateCustomQuestion(
                        { answer: e.target.value },
                        questionIndex
                      )
                    }
                    placeholder="e.g. 415-123-4567"
                  />
                </div>
                {this.questionHasError(question)
                  ? this.renderWarning("*This field cannot be empty")
                  : null}
              </div>
            );
          case "singleLine":
          default:
            return (
              <div
                className="mt-5 w-full font-size-14"
                key={`custom-questions-single-line-${questionIndex}`}
              >
                <div className="mb-2">
                  {question.description}
                  {question.required ? " *" : ""}
                </div>

                <input
                  className="availability-input"
                  value={
                    this.state.filteredQuestions[questionIndex]?.answer || ""
                  }
                  onChange={(e) =>
                    this.updateCustomQuestion(
                      { answer: e.target.value },
                      questionIndex
                    )
                  }
                />
                {this.questionHasError(question)
                  ? this.renderWarning("*This field cannot be empty")
                  : null}
              </div>
            );
        }
      });
    } else {
      return <></>;
    }
  }

  renderWarning(warningString) {
    return (
      <div
        className="event-form-different-time-zone-warning display-flex-flex-direction-row justify-end"
        style={{ fontSize: 12, width: "100%" }}
      >
        {warningString}
      </div>
    );
  }

  renderErrorWarning() {
    if (!this.state.errorWarning) {
      return null;
    }

    return (
      <div
        className="availability-display-error-warning-container"
        style={
          this.state.isMobile
            ? { borderRadius: 0 }
            : {
                top: this.shouldDisplayWelcomeInviteeBanner() ? -68 : 0,
                zIndex: 1,
              }
        }
      >
        <div
          style={{
            position: "absolute",
            top: 16,
            right: 16,
            cursor: "pointer",
          }}
          onClick={this.closeWarningMessage}
        >
          <X />
        </div>

        <div style={{ fontSize: 16 }}>{this.state.errorWarning.title}</div>

        <div style={{ fontSize: 14, marginTop: 8 }}>
          {this.state.errorWarning.subText}
        </div>
      </div>
    );
  }

  renderMobileSelectDayAndTimeModal() {
    return (
      <div
        className={
          "availability-select-day-time-container-is-mobile overflow-y-auto overflow-x-hidden"
        }
      >
        {this.renderGreeting()}
        {this.renderErrorWarning()}
        {this.renderEventInfo()}

        <ColoredLine />

        {this.determineSelectDateMethod()}
      </div>
    );
  }

  renderSelectDayAndTimeContainer() {
    return (
      <div
        className={
          "availability-select-day-time-container-is-not-mobile position-relative"
        }
      >
        {this.renderErrorWarning()}

        {this.renderEventInfoAndSelectDay()}

        {this.renderSelectTime()}
      </div>
    );
  }

  renderEventInfoAndSelectDay() {
    return (
      <div className="availability-left-container">
        {this.renderEventInfo()}

        <ColoredLine />

        {this.determineSelectDateMethod()}
      </div>
    );
  }

  determineSelectDateMethod() {
    const { isMobile, daySlots, selectedDay } = this.state;
    if (this.props.isPersonalLink && !isMobile) {
      return (
        <MonthlyCalendar
          onClickDaySlot={this.onClickDaySlot}
          availableSlots={daySlots}
          selectedDay={selectedDay}
        />
      );
    } else if (isMobile && daySlots && Object.keys(daySlots).length === 1) {
      return (
        <>
          {this.renderMobileSwipeDate()}
          <div className="availability-time-slot-container">
            {this.renderTimeSlots()}
          </div>
        </>
      );
    } else if (isMobile) {
      return this.renderMobileSelectDay();
    } else {
      return this.renderSelectDay();
    }
  }

  renderEventInfo(additionalClassName = null) {
    if (this.props.isReschedule) {
      return (
        <RescheduleLinkInfo
          info={this.state}
          additionalClassName={additionalClassName}
          selectedTimeZone={this.getSelectedTimeZone()}
          onSelectTimeZone={this.onSelectTimeZone}
        />
      );
    } else {
      return (
        <AvailabilityLinkEventInfo
          info={this.state}
          isGroupVoteSpreadSheet={this.isGroupVoteSpreadsheet()}
          additionalClassName={additionalClassName}
          companyName={this.getCompanyQuestionAnswer()}
          selectedTimeZone={this.getSelectedTimeZone()}
          shouldShowTimeZoneDropdown={this.shouldShowTimeZoneDropdown()}
          onSelectTimeZone={this.onSelectTimeZone}
        />
      );
    }
  }

  renderRescheduleLinkInfo() {
    return (
      <RescheduleLinkInfo
        info={this.state} 
        selectedTimeZone={this.getSelectedTimeZone()}
        onSelectTimeZone={this.onSelectTimeZone}
      />
    );
  }

  renderMobileSelectDay() {
    return (
      <div
        className={classNames("w-full", "flex flex-col justify-between")}
        style={{
          height: `calc(100% - ${
            this.shouldDisplayWelcomeInviteeBanner() ? "260px" : "200px"
          })`,
        }}
      >
        <div>
          <div className="pl-4 mt-6 mb-4 font-size-16 font-weight-500">
            Select Day
          </div>

          {this.renderDaySelection()}
        </div>
        <HoverableLogo containerClassName="mb-4 mt-4" />
      </div>
    );
  }

  renderSelectDay() {
    return (
      <div className="availability-bottom-left-container">
        <div
          className="availability-link-title"
          style={{
            paddingLeft: 30,
            paddingTop: 24,
            paddingBottom: 16,
            fontSize: 14,
            fontWeight: 300,
          }}
        >
          Select Day
        </div>

        <div className="select-availability-day-slots-container">
          {this.renderDaySelection()}
        </div>
      </div>
    );
  }

  renderDaySelection() {
    if (!this.state.daySlots || Object.keys(this.state.daySlots).length === 0) {
      return;
    }

    return this.state.sortedDayArray.map((k, index) => {
      return (
        <div
          key={`availability_day_slots_${index}`}
          onClick={() => this.onClickDaySlot(k)}
          className="availability-link-day-container display-flex-flex-direction-row position-relative"
          style={
            this.state.isMobile
              ? Object.assign(
                  {
                    width: "100%",
                    justifyContent: "center",
                  },
                  this.daySlotStyle(k)
                )
              : Object.assign(this.daySlotStyle(k))
          }
          onMouseEnter={() => this.onMouseEnterDaySlot(k)}
          onMouseLeave={this.onMouseLeave}
        >
          <div className="display-flex-flex-direction-row align-items-center">
            <div
              className="availability-link-day-side-bar"
              style={
                k === this.state.selectedDay
                  ? { backgroundColor: "#226DEC" }
                  : {}
              }
            ></div>

            <div
              className="select-none absolute font-size-14"
              style={{
                left: this.state.isMobile ? "15px" : "30px",
              }}
            >
              {k}
            </div>
          </div>
        </div>
      );
    });
  }

  renderSelectTime() {
    return (
      <div className="render-right-hand-container">
        {this.props.isReschedule &&
        !this.state.allow_reschedule_and_cancel &&
        !this.props.isPersonalLink ? (
          "This meeting can not be rescheduled."
        ) : (
          <>
            {this.renderRightHandTitle()}

            <div className="availability-time-slot-container">
              {this.renderTimeSlots()}
            </div>
          </>
        )}
      </div>
    );
  }

  renderRightHandTitle() {
    if (!this.state.selectedDay) {
      return;
    }

    return (
      <div className="margin-bottom-25">
        <div className="right-hand-title">
          {moment(this.state.selectedDay).format("LL")}
        </div>

        {this.renderTitleTime()}
      </div>
    );
  }

  renderTitleTime() {
    if (this.state.selectedTime) {
      return (
        <div className="render-title-time">
          {moment(this.state.selectedTime.start).format("dddd")}{" "}
          {moment(this.state.selectedTime.start).format(TIME_FORMAT)} -{" "}
          {moment(this.state.selectedTime.end).format(TIME_FORMAT)}
        </div>
      );
    } else {
      return <div className="h-6"></div>;
    }
  }

  renderTimeSlots() {
    if (!this.state.selectedDay || !this.state.daySlots) {
      return null;
    }

    return this.state.daySlots[this.state.selectedDay].map((s, index) => {
      return this.renderSelectedTimeSlot(s, index);
    });
  }

  renderSelectedTimeSlot(s, index) {
    let shouldDisplayConfirm =
      this.state.selectedTime && s.start === this.state.selectedTime.start;
    const { isMobile } = this.state;
    return (
      <div
        key={`availability_selected_time_slot_${index}`}
        className={classNames(
          "display-flex-flex-direction-row width-100-percent",
          isMobile ? "justify-center" : "flex-start"
        )}
        onClick={() => this.onClickTimeSlot(s)}
      >
        <div
          className={Classnames(
            "select-none",
            "availability-link-time-slot-container",
            shouldDisplayConfirm
              ? "availability-confirm-time-width mr-1"
              : "availability-time-width"
          )}
        >
          {shouldDisplayConfirm && !this.state.isMobile
            ? `${moment(s.start).format(TIME_FORMAT)} - ${moment(s.end).format(
                TIME_FORMAT
              )}`
            : `${moment(s.start).format(TIME_FORMAT)}`}
        </div>

        <div
          className={Classnames(
            "confirm-button",
            shouldDisplayConfirm ? "availability-confirm-time-width" : ""
          )}
          style={shouldDisplayConfirm ? { marginRight: 10 } : { width: 0 }}
          onClick={this.onClickConfirmOnTime}
        >
          {shouldDisplayConfirm ? "Confirm" : ""}
        </div>
      </div>
    );
  }

  getResponseBeforeAndAfterBuffer(response) {
    const bufferBefore = response?.availabilities?.buffer_before ?? 0;
    const bufferAfter = response?.availabilities?.buffer_after ?? 0;
    return {
      bufferBefore,
      bufferAfter,
    };
  }

  getResponseBufferFromNow(response) {
    return (
      response?.availabilities?.buffer_from_now ??
      this.state.buffer_from_now ??
      0
    );
  }

  constructDayAndTimeSlots(response) {
    let freeSlots = [];
    const startBuffer = startOfMinute(subMinutes(new Date(), 5)).toISOString();
    const endBuffer = startOfMinute(addHours(new Date(), 4)).toISOString();
    let createdAt = response?.availabilities?.created_at
      ? parseISO(response?.availabilities?.created_at)
      : null;

    // add buffer if created in the last 4 hours
    const shouldAddBuffer =
      createdAt && differenceInHours(new Date(), createdAt) > 4;
    const bufferParam = this.getResponseBeforeAndAfterBuffer(response);

    const currentTimeBuffer = [
      {
        start: subMinutes(new Date(), 30).toISOString(),
        end: addMinutes(
          new Date(),
          this.getResponseBufferFromNow(response)
        ).toISOString(),
      },
    ];

    response.free_busy.forEach((s, index) => {
      const formattedBusySlotsWithBufferBeforeAndAfter = s.busy_slots.map(
        (slot) => {
          // return slot;
          return {
            start: addBufferToEvents({
              time: slot.start,
              isAllDay: false,
              shouldAddBuffer: true,
              isBufferBefore: true,
              param: bufferParam,
              isOutlook: slot.is_outlook,
            }),
            end: addBufferToEvents({
              time: slot.end,
              isAllDay: false,
              shouldAddBuffer: true,
              isBufferBefore: false,
              param: bufferParam,
              isOutlook: slot.is_outlook,
            }),
          };
        }
      );

      let busySlots = mergeBusySlots(
        currentTimeBuffer.concat(formattedBusySlotsWithBufferBeforeAndAfter)
      );

      if (index === 0 && shouldAddBuffer) {
        busySlots = [{ start: startBuffer, end: endBuffer }].concat(busySlots);
      }

      freeSlots = freeSlots.concat(
        createFreeSlotsBasedOnBusySlots(s.start_time, s.end_time, busySlots)
      );
    });

    const duration = this.state.duration || response.availabilities.duration;
    const daySlots = sortTimeSlots(
      createSlotsForSelection({
        timeSlots: freeSlots,
        duration,
        timeZone: this.getSelectedTimeZone()
      })
    );

    if (IsEmptyObject(daySlots)) {
      this.setState({ areAllSlotsBusy: true });
      this.trackEvent("all_links_are_within_time");
      return;
    }

    let selectedDay =
      daySlots && Object.keys(daySlots).length > 0 && Object.keys(daySlots)[0];

    let selectedIndexDay;

    response.free_busy.forEach((s) => {
      if (s.index === this.state.index) {
        selectedIndexDay = format(
          convertToTimeZone(s.start_time, { timeZone: this.getSelectedTimeZone() }),
          TIME_BLOCK_FORMAT
        );
      }
    });

    if (selectedIndexDay && Object.keys(daySlots).includes(selectedIndexDay)) {
      selectedDay = selectedIndexDay;
    } else if (this.state.doesNotHaveIndex) {
      // Do nothing -> no index (non embedded link)
    } else if (
      selectedIndexDay &&
      moment(selectedIndexDay).isBefore(moment(), "day")
    ) {
      // date is in the past
      this.displayErrorWarning(PAST_DAY);
    } else if (selectedIndexDay) {
      // day is all filled

      this.displayErrorWarning(SELECTED_DAY_UNAVAILABLE);
    } else if (!selectedIndexDay) {
      this.displayErrorWarning(PAST_DAY);
    }

    const daysArray = daySlots ? Object.keys(daySlots) : [];

    const sortedDayArray = daysArray.sort((a, b) => SortArrayByDate(a, b));

    return { sortedDayArray, selectedDay, daySlots };
  }

  bookAvailabiity() {
    /* We create a unique token for the meeting and pass it to description */
    /* We also create the appointment with that token on backend */
    /* link_token is the token of the token for the availability_link we connect to */
    const meetingToken = generateAvailabilityToken(this.state.token);
    this._meetingToken = meetingToken;
    const eventData = this.constructEventData({ meetingToken });
    const path = `${getSlotsPath()}/book`;
    const params = {
      sendUpdates: "all",
      conferenceDataVersion: 1,
      link_type: BACKEND_AVAILABILITY,
      link_token: this.state.token,
      meeting_token: meetingToken,
    };

    const queryParams = constructQueryParams(params);
    const url = constructRequestURL(path, isV2()) + `?${queryParams}`;

    const payloadData = { body: JSON.stringify(eventData) };

    this.submitBook(url, payloadData);
  }

  submitBook(url, payloadData) {
    const { token } = this.state;

    let sentryContext = {
      token,
      url,
      payloadData,
      type: "booking link",
      action: "submit booking",
      user: this.state.user,
    };

    return Fetcher.post(url, payloadData)
      .then((response) => {
        if (!this._isMounted) {
          return;
        }

        this.trackBooking("submit_book");

        sentryContext.response = response;
        if (this.doesResponseHaveErrorAndHandleError(response)) {
          return;
        }

        this.setState({ showSentConfirmationEmail: true });
      })
      .catch((error) => {
        this.handleCatchError(error, sentryContext, false, true);
      });
  }

  submitReschedule(url, payloadData) {
    let sentryContext = {
      token: this.state.token,
      url,
      type: "reschedule",
      action: "submit reschedule",
      payloadData,
      user: this.state.user,
    };

    return Fetcher.patch(url, payloadData)
      .then((response) => {
        if (!this._isMounted) {
          return;
        }

        this.trackBooking("submit_reschedule");

        sentryContext.response = response;
        if (this.doesResponseHaveErrorAndHandleError(response)) {
          return;
        }

        this.setState({ showSentConfirmationEmail: true });
      })
      .catch((error) => {
        this.handleCatchError(error, sentryContext, false, true);
      });
  }

  rescheduleAvailabilityLink() {
    const path = `${getSlotsPath()}/appointment/${this.state.token}`;
    const url = constructRequestURL(path, isV2());

    const { user } = this.state;
    const sentryContext = {
      token: this.state.token,
      path,
      url,
      type: "reschedule",
      action: "get reschedule data",
      user,
    };

    return Fetcher.get(url)
      .then((response) => {
        if (!this._isMounted) {
          return;
        }
        this.trackLoadTime("rescheduleAvailabilityLink");

        sentryContext.response = response;
        if (
          this.doesResponseHaveErrorAndHandleError(
            response,
            this.shouldRefresh()
          )
        ) {
          return;
        }

        const { event, availability_link } = response;
        if (
          !event ||
          !getEventStartString(event)
        ) {
          sentryContext.event = event;
          handleError(new Error("error with event"), sentryContext);

          this.setState({ error: true });
          return;
        }

        // if (this.checkForPastEventAndHandlePastEvent(response)) {
        //   return;
        // }

        let {
          duration,
          title,
          location,
          conferencing,
          slots,
          time_zone,
          buffer_before,
          buffer_after,
          days_forward,
          description,
          attendees,
          token,
          custom_questions: customQuestion,
          provider,
        } = availability_link;

        const { user_calendar_id } = event;
        const oldStartTime = getResponseStartTime(response);

        this.setState(
          {
            userCalendarId: user_calendar_id,
            event,
            availabilityLink: availability_link,
            duration,
            title: getTitle({ title, customQuestions: customQuestion }),
            location,
            conferencing: getConferencing({ user, provider, conferencing }),
            isPersonalLink: false,
            slots,
            time_zone,
            buffer_before,
            buffer_after,
            days_forward,
            description,
            attendees,
            token,
            roundUpInterval: shouldRoundToNearest15(duration) ? 15 : 30,
            oldStartTime,
            backendAppointmentToken: this.state.token,
          },
          this.fetchAvailability
        );
      })
      .catch((error) => {
        let shouldRefresh = this.shouldRefresh();
        this.handleCatchError(error, sentryContext, shouldRefresh);

        if (this._isMounted && shouldRefresh) {
          this.setState(
            { refreshCount: this.state.refreshCount + 1 },
            this.reschedulePersonalLink
          );
        }
      });
  }

  reschedulePersonalLink() {
    const path = `${getSlotsPath()}/appointment/${this.state.appointmentToken}`;
    const url = constructRequestURL(path, isV2());

    const { user } = this.state;
    const sentryContext = {
      token: this.state.token,
      path,
      url,
      type: "reschedule",
      action: "get reschedule data",
      user,
    };

    return Fetcher.get(url)
      .then((response) => {
        if (!this._isMounted) {
          return;
        }
        this.trackLoadTime("reschedulePersonalLink");

        sentryContext.response = response;
        if (
          this.doesResponseHaveErrorAndHandleError(
            response,
            this.shouldRefresh()
          )
        ) {
          return;
        }

        let { event, personal_link } = response;
        if (
          !event ||
          !getEventStartString(event)
        ) {
          sentryContext.event = event;
          handleError(new Error("error with event"), sentryContext);

          this.setState({ error: true });
          return;
        }

        // if (this.checkForPastEventAndHandlePastEvent(response)) {
        //   return;
        // }

        let {
          duration,
          title,
          location,
          conferencing,
          isPersonalLink,
          slots,
          time_zone,
          buffer_before,
          buffer_after,
          days_forward,
          description,
          attendees,
          custom_questions: customQuestions,
          token,
          buffer_from_now,
          trips,
          provider,
        } = personal_link;

        let { user_calendar_id } = event;
        const oldStartTime = getResponseStartTime(response);

        this.setState(
          {
            userCalendarId: user_calendar_id,
            event,
            personalLink: personal_link,
            duration,
            title: getTitle({ title, customQuestions }),
            location,
            conferencing: getConferencing({ user, provider, conferencing }),
            isPersonalLink,
            slots,
            time_zone,
            buffer_before,
            buffer_after,
            days_forward,
            description,
            attendees,
            token,
            roundUpInterval: shouldRoundToNearest15(duration) ? 15 : 30,
            buffer_from_now,
            oldStartTime,
            backendAppointmentToken: this.state.appointmentToken,
            trips,
          },
          this.fetchPersonalLinkAvailability
        );
      })
      .catch((error) => {
        let shouldRefresh = this.shouldRefresh();
        this.handleCatchError(error, sentryContext, shouldRefresh);

        if (this._isMounted && shouldRefresh) {
          this.setState(
            { refreshCount: this.state.refreshCount + 1 },
            this.reschedulePersonalLink
          );
        }
      });
  }

  checkForPastEventAndHandlePastEvent(response) {
    if (isEventStartBeforeNow(response)) {
      this.setState({ hasEventPassed: true });
      return true;
    }

    return false;
  }

  fetchPersonalLink() {
    const { token, user } = this.state;
    const { match } = this.props;
    const { slug, username } = match.params;

    const url = this.getPersonalLinkUrl();

    const sentryContext = {
      token,
      type: "personal link",
      action: "fetch personal link data",
      user,
      slug,
      username,
    };

    return Fetcher.get(url)
      .then((response) => {
        if (!this._isMounted) {
          return;
        }
        this.trackLoadTime("fetchPersonalLink");

        sentryContext.response = response;
        if (
          this.doesResponseHaveErrorAndHandleError(
            response,
            this.shouldRefresh()
          )
        ) {
          return;
        }
        const { personal_link } = response;

        let {
          duration,
          title,
          location,
          conferencing,
          // TODO: This property isn't included in the response. Is it safe to remove, or should we
          // add it to the response?
          isPersonalLink,
          slots,
          time_zone,
          buffer_before,
          buffer_after,
          days_forward,
          description,
          attendees,
          custom_questions: customQuestions,
          buffer_from_now,
          token: responseToken,
          trips,
          social_links,
          profile_photo_url,
          settings,
          provider,
        } = personal_link;

        const plTitle = getTitle({ title, customQuestions });
        this.setState(
          {
            duration,
            title: plTitle,
            location,
            conferencing: getConferencing({ user, provider, conferencing }),
            isPersonalLink,
            slots,
            time_zone,
            buffer_before,
            buffer_after,
            days_forward,
            description,
            attendees,
            host_time_zone: time_zone || guessTimeZone(),
            custom_questions: getCustomQuestions({
              questions: customQuestions,
              title: plTitle,
            }),
            filteredQuestions: customQuestions?.filter(
              (q) => !isNameOrEmailQuestion(q)
            ),
            roundUpInterval: shouldRoundToNearest15(duration) ? 15 : 30,
            buffer_from_now,
            token: responseToken,
            trips,
            social_links,
            profile_photo_url,
            settings,
          },
          this.fetchPersonalLinkAvailability
        );
      })
      .catch((error) => {
        let shouldRefresh = this.shouldRefresh();
        this.handleCatchError(error, sentryContext, shouldRefresh);

        if (this._isMounted && shouldRefresh) {
          this.setState(
            { refreshCount: this.state.refreshCount + 1 },
            this.fetchPersonalLink
          );
        }
      });
  }

  getPersonalLinkAvailabilityFetchData() {
    const {
      slots,
      time_zone: timeZone,
      days_forward: daysForward,
      buffer_before: bufferBefore,
      buffer_after: bufferAfter,
      trips,
      conferencing,
      token,
    } = this.state;
    const path = `personal_links/${token}/availability`;
    const url = constructRequestURL(path, isV2());
    const bookableSlots = generateBookableSlotsFromObj({
      slots,
      timeZone,
      daysForward,
      shouldFormatIntoUTC: false,
      bufferBefore,
      bufferAfter,
      upcomingTrips: trips,
    });
    const linkData = {
      conferencing,
      time_slots: bookableSlots,
    };
    const payloadData = {
      headers: { "Accept-Encoding": "gzip" },
      body: JSON.stringify(linkData),
    };

    return {
      path,
      url,
      bookableSlots,
      linkData,
      payloadData,
    };
  }

  fetchPersonalLinkAvailability(isNew = true) {
    const {
      path,
      url,
      bookableSlots,
      payloadData,
    } = this.getPersonalLinkAvailabilityFetchData();

    if (!checkIfBookableSlotsAreValid(bookableSlots)) {
      this.setState({ areAllSlotsBusy: true });
      return;
    }
    const {
      token,
      user,
    } = this.state;

    const sentryContext = {
      token,
      url,
      payloadData,
      path,
      type: "personal link",
      action: "fetch personal link availability",
      user,
    };

    return Fetcher.post(url, payloadData)
      .then((response) => {
        if (!this._isMounted) {
          return;
        }

        sentryContext.response = response;
        if (
          this.doesResponseHaveErrorAndHandleError(
            response,
            this.shouldRefresh()
          )
        ) {
          return;
        }

        let {
          free_busy,
          personal_link,
          user_calendar_id,
          zoom_link,
          phone_number,
          phone_region_code,
          user,
          default_zoom_mode,
          custom_conferencing_name,
          custom_conferencing_url,
          master_account,
          is_scheduling_for_exec,
          schedule_for,
        } = response;

        if (getUserEmail(user)) {
          Sentry.configureScope(function (scope) {
            scope.setUser({ email: getUserEmail(user) });
          });
        }

        this.setState(
          {
            response,
            free_busy,
            personal_link,
            user_calendar_id,
            zoom_link,
            phone_number,
            phone_region_code,
            user,
            default_zoom_mode,
            appointmentToken: generateRandomId(16),
            custom_conferencing_name,
            custom_conferencing_url,
            provider: user.provider,
            master_account,
            is_scheduling_for_exec,
            scheduleFor: schedule_for,
            conferencing: getConferencing({
              user,
              conferencing: this.state.conferencing,
            }), // get user back
          },
          () => this.createAndSetPersonalLinkPreviewData(response)
        );
      })
      .catch((error) => {
        let shouldRefresh = this.shouldRefresh();
        this.handleCatchError(error, sentryContext, shouldRefresh);

        if (this._isMounted && shouldRefresh) {
          this.setState({ refreshCount: this.state.refreshCount + 1 }, () => {
            this.fetchPersonalLinkAvailability(isNew);
          });
        }
      });
  }

  shouldRefresh() {
    return this.state.refreshCount < 3;
  }

  doesResponseHaveErrorAndHandleError(response, doNotSetError = false) {
    let hasError = false;

    if (!response) {
      createError("no response");
      hasError = true;
      !doNotSetError && this.setState({ error: true });
    } else if (response.error) {
      hasError = true;
      const {
        error,
      } = response;
      if (error === "expired") {
        this.setState({ expired: true });
      } else if (error === "invalid_grant") {
        // when user needs to auth again.
        hasError = true;
        const updatedState = { calendarsUnavailable: true };
        if (response?.user) {
          updatedState.user = response.user;
        }
        this.setState(updatedState);
      } else if (error === "not_found") {
        this.setState({ notFound: true });
      } else {
        createError("error in response");
        !doNotSetError && this.setState({ error: true });
      }
    } else if (response.type === "error") {
      createError("response type error");

      hasError = true;

      !doNotSetError && this.setState({ error: true });
    } else if (response.backtrace) {
      createError("response type error - backtrack");
      hasError = true;
      !doNotSetError && this.setState({ error: true });
      return true;
    }

    return hasError;
  }

  handleCatchError(
    error,
    context,
    doNotShowError = false,
    shouldUpdateSubmitting = false
  ) {
    handleError(error, context);

    if (!this._isMounted || doNotShowError) {
      return;
    }

    if (context?.response?.error === "NoAvailabilityError") {
      this.setState({ error: true, noAvailabilityError: true });
      return;
    }

    this.setState({ error: true });
  }

  
  createPersonalLinkPreviewData(response) {
    const {
      roundUpInterval,
      buffer_before: bufferBefore,
      buffer_after: bufferAfter,
      buffer_from_now: bufferFromNow,
    } = this.state;
    const allBusySlots = getAllBusySlots({
      response,
      roundUpInterval,
      bufferBefore,
      bufferAfter,
      bufferFromNow,
    });

    const allFreeSlots = this.getFreeTimes(allBusySlots);

    const dayTimeSlots =
      this.constructDayAndTimeSlotsForPersonalLink(allFreeSlots);
    if (!dayTimeSlots) {
      return;
    }

    const { sortedDayArray, selectedDay, daySlots } = dayTimeSlots;

    return {
      sortedDayArray,
      selectedDay,
      daySlots,
    }
  }

  createAndSetPersonalLinkPreviewData(response) {
    try {
      const dayTimeSlots = this.createPersonalLinkPreviewData(response);
      if (!dayTimeSlots) {
        return;
      }
      const {
        zoom_link,
        phone_region_code,
        phone_number,
      } = this.state;
      const { sortedDayArray, selectedDay, daySlots } = dayTimeSlots;
      this.setState({
        sortedDayArray,
        selectedDay,
        daySlots,
        zoomLink: zoom_link,
        phoneNumber: {
          regionCode: phone_region_code,
          number: phone_number,
        },
        userCalendarId: response.user_calendar_id,
      });
    } catch(error) {
      handleError(error);
    }
  }

  getFreeTimes(busySlots) {
    const {
      slots,
      days_forward: daysForward,
      duration,
      time_zone: timeZone,
      trips,
    } = this.state;
    return generateFreeSlotsFromBusySlots({
      busySlots: busySlots ?? [],
      slots,
      daysForward,
      durationMinutes: duration,
      timeZone,
      upcomingTrips: trips,
    });
  }

  constructDayAndTimeSlotsForPersonalLink(freeSlots) {
    // freeSlots is the result of taking out the busy slots
    if (freeSlots.length === 0) {
      this.setState({ areAllSlotsBusy: true });
      return;
    }

    const daySlots = sortTimeSlots(
      createSlotsForSelection({
        timeSlots: freeSlots, 
        duration: this.state.duration,
        timeZone: this.getSelectedTimeZone()
      })
    );

    if (IsEmptyObject(daySlots)) {
      this.setState({ areAllSlotsBusy: true });
      return;
    }

    let daysArray = daySlots ? Object.keys(daySlots) : [];

    let sortedDayArray = daysArray.sort((a, b) => SortArrayByDate(a, b));

    let selectedDay = sortedDayArray[0];

    return { sortedDayArray, selectedDay, daySlots };
  }

  createSentryContext(action) {
    try {
      const { token, user } = this.state;
      const { match } = this.props;
      return {
        token,
        type: "availabilityLink.js",
        action,
        user,
        slug: match?.params?.slug,
        username: match?.params?.username,
      }; 
    } catch(error) {
      return null;
    }
    
  }

  async fetchGroupVoteSpreadSheet() {
    const { token, user } = this.state;
    const { match } = this.props;
    const { slug, username } = match.params;

    const url = this.getGroupVoteSpreadSheetUrl();
    const sentryContext = {
      token,
      type: "group vote spreadsheet",
      action: "fetch group vote spreadsheet",
      user,
      slug,
      username,
    };

    try {
      const response = await Fetcher.get(url);
      // const response = SAMPLE_BACKEND_GROUP_VOTE_SPRSHEET_RESPONSE; // for testing
      if (!this._isMounted) {
        return;
      }
      this.trackLoadTime("fetchGroupVoteSpreadSheetLink");

      sentryContext.response = response;

      if (
        this.doesResponseHaveErrorAndHandleError(response, this.shouldRefresh())
      ) {
        return;
      } else if (!response?.group_spreadsheet_link) {
        createError("response does not include group vote spreadsheet object");
        this.setState({ error: true });
        return;
      }

      const { group_spreadsheet_link } = response;
      const {
        title,
        duration,
        location,
        conferencing,
        attendees,
        description,
        selected_slots,
        time_zone,
        user: responseUser,
        token: responseToken,
        social_links,
        profile_photo_url,
        provider,
      } = group_spreadsheet_link;

      if (getUserEmail(responseUser)) {
        Sentry.configureScope(function (scope) {
          scope.setUser({ email: getUserEmail(responseUser) });
        });
      }

      if (
        getNonExpiredSelectedSlotsWithDefaultTimeZone(group_spreadsheet_link)
          .length === 0
      ) {
        this.setState({ expired: true });
        return;
      }
      const selectedTimeZone = guessTimeZone();

      this.setState({
        title,
        duration,
        location,
        conferencing: getConferencing({
          user: responseUser,
          provider,
          conferencing,
        }),
        attendees,
        description,
        selectedSlots: selected_slots,
        timeZone: time_zone,
        selectedTimeZone,
        groupVoteLink: group_spreadsheet_link,
        user: responseUser,
        isGroupVote: true,
        token: responseToken,
        social_links,
        profile_photo_url,
      });
    } catch (error) {
      let shouldRefresh = this.shouldRefresh();
      this.handleCatchError(error, sentryContext, shouldRefresh);

      if (this._isMounted && shouldRefresh) {
        this.setState(
          { refreshCount: this.state.refreshCount + 1 },
          this.fetchAvailability
        );
      }
    }
  }

  fetchGroupVoteLink() {
    const { token, user } = this.state;
    const { match } = this.props;
    const { slug, username } = match.params;

    const url = this.getGroupVoteUrl();

    let sentryContext = {
      token,
      type: "group vote",
      action: "fetch group vote",
      user,
      slug,
      username,
    };

    return Fetcher.get(url)
      .then((response) => {
        if (!this._isMounted) {
          return;
        }
        this.trackLoadTime("fetchGroupVoteLink");

        sentryContext.response = response;

        if (
          this.doesResponseHaveErrorAndHandleError(
            response,
            this.shouldRefresh()
          )
        ) {
          return;
        } else if (!response?.group_vote_link) {
          createError("response does not include group vote object");
          this.setState({ error: true });
          return;
        }

        const { group_vote_link } = response;
        const {
          title,
          duration,
          location,
          conferencing,
          attendees,
          description,
          selected_slots,
          time_zone,
          user,
          token: responseToken,
          social_links,
          profile_photo_url,
          provider,
        } = group_vote_link;

        if (getUserEmail(user)) {
          Sentry.configureScope(function (scope) {
            scope.setUser({ email: getUserEmail(user) });
          });
        }

        if (
          getNonExpiredSelectedSlotsWithDefaultTimeZone(group_vote_link)
            .length === 0
        ) {
          this.setState({ expired: true });
          return;
        }

        this.setState({
          title,
          duration,
          location,
          conferencing: getConferencing({ user, provider, conferencing }),
          attendees,
          description,
          selectedSlots: selected_slots,
          timeZone: time_zone,
          groupVoteLink: group_vote_link,
          user,
          isGroupVote: true,
          token: responseToken,
          social_links,
          profile_photo_url,
        });
      })
      .catch((error) => {
        let shouldRefresh = this.shouldRefresh();
        this.handleCatchError(error, sentryContext, shouldRefresh);

        if (this._isMounted && shouldRefresh) {
          this.setState(
            { refreshCount: this.state.refreshCount + 1 },
            this.fetchAvailability
          );
        }
      });
  }

  recalculateDayAndTimeSlots() {
    const { response } = this.state;
    if (IsEmptyObject(response)) {
      return;
    }
    if (this.props.isGroupVoteSpreadSheet || this.props.isGroupVoteLink) {
      // should not get inside here, more so sanity check
      return;
    }
    if (this.props.isPersonalLink) {
      this.createAndSetPersonalLinkPreviewData(response);
      return;
    }
    // slots
    const dayTimeSlots = this.constructDayAndTimeSlots(response);
    if (!dayTimeSlots) {
      return;
    }
    const { sortedDayArray, selectedDay, daySlots } = dayTimeSlots;
    this.setState({ sortedDayArray, selectedDay, daySlots });
  }

  fetchAvailability() {
    const { token, user } = this.state;
    const { match } = this.props;
    const { slug, username } = match.params;

    const url = this.getSlotsUrl();

    const sentryContext = {
      token,
      type: "booking link",
      action: "fetch availability",
      user,
      slug,
      username,
    };

    return Fetcher.get(url)
      .then((response) => {
        if (!this._isMounted) {
          return;
        }
        this.trackLoadTime("fetchAvailability");

        sentryContext.response = response;

        if (
          this.doesResponseHaveErrorAndHandleError(
            response,
            this.shouldRefresh()
          )
        ) {
          return;
        }

        const {
          custom_conferencing_name,
          custom_conferencing_url,
          provider,
          master_account,
          is_scheduling_for_exec,
        } = response;

        let {
          title,
          duration,
          location,
          conferencing,
          user,
          client_time_zone,
          invitee_full_name,
          invitee_email,
          attendees,
          description,
          calendar_provider_id,
          google_calendar_id,
          sender_full_name,
          custom_questions,
          buffer_from_now,
          allow_reschedule_and_cancel,
          token: responseToken,
          buffer_before,
          buffer_after,
          profile_photo_url,
          social_links,
          settings,
        } = response.availabilities;

        if (getUserEmail(user)) {
          Sentry.configureScope(function (scope) {
            scope.setUser({ email: getUserEmail(user) });
          });
        }

        if (!this._isMounted || !response) {
          return;
        }

        const dayTimeSlots = this.constructDayAndTimeSlots(response);

        if (!dayTimeSlots) {
          return;
        }

        const { sortedDayArray, selectedDay, daySlots } = dayTimeSlots;

        const slotTitle = getTitle({
          title,
          customQuestions: custom_questions,
        });
        const customQuestions = getCustomQuestions({
          questions: custom_questions,
          title: slotTitle,
        });
        this.setState({
          response,
          title: slotTitle,
          duration,
          location,
          conferencing: getConferencing({ user, provider, conferencing }),
          selectedDay,
          user,
          daySlots,
          sortedDayArray,
          zoomLink: response.zoom_link,
          phoneInfo: {
            regionCode: response.phone_region_code,
            number: response.phone_number,
          },
          phone_number: response.phone_number,
          phone_region_code: response.phone_region_code,
          userCalendarId: response.user_calendar_id,
          freeBusy: response.free_busy,
          host_time_zone: client_time_zone || guessTimeZone(),
          invitee_full_name: invitee_full_name ?? this.state.inputName ?? "",
          invitee_email: invitee_email ?? this.state.inputEmail ?? "",
          attendees: attendees ? JSON.parse(attendees) : [],
          inputName: invitee_full_name
            ? CapitalizeFirstLetterOfEveryWord(invitee_full_name)
            : this.state.inputName,
          inputEmail: invitee_email || this.state.inputEmail,
          default_zoom_mode: response.default_zoom_mode,
          custom_conferencing_name,
          custom_conferencing_url,
          description,
          calendar_provider_id,
          google_calendar_id,
          sender_full_name,
          customQuestions,
          filteredQuestions: customQuestions?.filter(
            (q) => !isNameOrEmailQuestion(q)
          ),
          buffer_from_now,
          allow_reschedule_and_cancel,
          scheduleFor: response.schedule_for,
          provider,
          token: responseToken,
          master_account,
          is_scheduling_for_exec,
          buffer_before,
          buffer_after,
          profile_photo_url,
          social_links,
          roundUpInterval: shouldRoundToNearest15(duration) ? 15 : 30,
          settings,
        });
      })
      .catch((error) => {
        let shouldRefresh = this.shouldRefresh();
        this.handleCatchError(error, sentryContext, shouldRefresh);

        if (this._isMounted && shouldRefresh) {
          this.setState(
            { refreshCount: this.state.refreshCount + 1 },
            this.fetchAvailability
          );
        }
      });
  }

  onClickSchedule() {
    if (this.checkNameEmailInputForError()) {
      return;
    }

    if (this._isSubmitting) {
      return;
    }

    this._isSubmitting = true;
    this.setState({ isSubmitting: true });

    if (this.isGroupVoteSpreadsheet()) {
      const createUpsertAttendee = () => {
        const { attendees, inputName: name, inputEmail: email } = this.state;
        // we use this view for spreadsheet in mobile view
        const selectedSlots = convertSlotsIntoISOString(
          this.getGroupVoteSelectedSlots()
        ).map((slot) => {
          return {
            ...slot,
            selected: true,
          };
        });

        const matchingAttendee = attendees?.find((attendee) =>
          isSameEmail(getAttendeeEmail(attendee), email)
        );
        if (matchingAttendee) {
          const getUpdatedSlots = () => {
            // if slot already exists in matching attendee slots, update it
            // otherwise concat it
            const matchingAttendeeSlots = matchingAttendee.slots ?? [];
            selectedSlots.forEach((itemA) => {
              const index = matchingAttendeeSlots.findIndex(
                (itemB) =>
                  createKeyFromSlotISOString(itemB) ===
                  createKeyFromSlotISOString(itemA)
              );
              if (index > -1) {
                // Replace the element in arrB with the element from arrA
                matchingAttendeeSlots[index] = {
                  ...matchingAttendeeSlots[index],
                  selected: true,
                };
              } else {
                // Concatenate the element from arrA to arrB
                matchingAttendeeSlots.push(itemA);
              }
            });
            return matchingAttendeeSlots;
          };
          return {
            ...matchingAttendee,
            name: name || matchingAttendee.name,
            slots: getUpdatedSlots(),
          };
        }

        // create new email
        return {
          uuid: createUUID(),
          name,
          email,
          slots: selectedSlots,
        };
      };
      this.submitGroupVoteSpreadsheet({
        edited_attendees: [createUpsertAttendee()],
        deleted_attendees: [],
      });
      return;
    }
    if (this.state.isGroupVote) {
      this.submitGroupVote();
    } else if (this.props.isPersonalLink) {
      this.onClickSchedulePersonalLink();
    } else {
      this.onClickScheduleAvailability();
    }
  }

  determineUrl() {
    let environment = process.env.REACT_APP_CLIENT_ENV;

    switch (environment) {
      case "dev":
        return "https://book-dev.vimcal.com";
      case "staging":
        return "https://book-staging.vimcal.com";
      case "dogfood":
        // Using prod instead of dogfood because users will be sending these out
        return "https://book.vimcal.com";
      case "production":
        return "https://book.vimcal.com";
      case "testing":
        return "https://book-testing.vimcal.com";
      default:
        return "https://book-dev.vimcal.com";
    }
  }

  // responses is what we're passing to the backend
  async submitGroupVoteSpreadsheet(responses) {
    const { token, user } = this.state;
    const path = `group_spreadsheet_links/${token}/response`;
    const url = constructRequestURL(path, isV2());
    const payloadData = {
      body: JSON.stringify({
        responses,
      }),
    };

    const sentryContext = {
      token,
      url,
      payloadData,
      type: "group vote",
      action: "submit group vote",
      user,
    };

    try {
      const response = await Fetcher.patch(url, payloadData);
      if (!this._isMounted) {
        return;
      }

      this.trackBooking("submit_group_vote_spreadsheet");

      sentryContext.response = response;
      if (this.doesResponseHaveErrorAndHandleError(response)) {
        return;
      }

      this.setState({
        showSentConfirmationEmail: true,
        onClickedConfirmTime: true,
      });
    } catch (error) {
      handleError(error, sentryContext);
      this.setState({ error: true });
    }
  }

  getGroupVoteSelectedSlots() {
    const selectedTimeZone = this.getSelectedTimeZone();
    const { newAttendeeSlots } = this.state;
    if (selectedTimeZone !== guessTimeZone()) {
      return newAttendeeSlots.map((slot) => {
        if (isEventSlotAllDayEvent(slot)) {
          // no need to change time zone if it's all day
          return slot;
        }
        const { eventStart, eventEnd } = slot;
        return {
          ...slot,
          eventStart: getTimeInAnchorTimeZone(eventStart, selectedTimeZone),
          eventEnd: getTimeInAnchorTimeZone(eventEnd, selectedTimeZone),
        };
      });
    }
    return newAttendeeSlots;
  }

  submitGroupVote() {
    const path = `group_vote_links/${this.state.token}/response`;
    const url = constructRequestURL(path, isV2());

    const payloadData = {
      body: JSON.stringify({
        attendee: {
          name: this.state.inputName,
          email: this.state.inputEmail,
          slots: convertSlotsIntoISOString(this.getGroupVoteSelectedSlots()),
        },
      }),
    };

    let sentryContext = {
      token: this.state.token,
      url,
      payloadData,
      type: "group vote",
      action: "submit group vote",
      user: this.state.user,
    };

    Fetcher.patch(url, payloadData)
      .then((response) => {
        if (!this._isMounted) {
          return;
        }

        this.trackBooking("submit_group_vote");

        sentryContext.response = response;
        if (this.doesResponseHaveErrorAndHandleError(response)) {
          return;
        }

        this.setState({ showSentConfirmationEmail: true });
      })
      .catch((error) => {
        this.handleCatchError(error, true);
      });
  }

  getZoomURLAndPayload() {
    const { isPersonalLink } = this.props;

    const { scheduleFor, selectedTime, token, user } = this.state;

    const createZoomUniqueLinkParam = () => {
      const zoomData = {
        meeting: {
          topic: isPersonalLink
            ? this.createPersonalLinkSummary()
            : this.createBookingLinkSummary(),
          timezone: UTC_TIME_ZONE,
          start_time: moment(selectedTime.start).toDate().toISOString(),
          duration: moment(selectedTime.end)
            .startOf("minute")
            .diff(moment(selectedTime.start).startOf("minute"), "minute"),
        },
      };

      if (scheduleFor) {
        zoomData.meeting.schedule_for = scheduleFor;
      }

      return zoomData;
    };

    const path = `${
      isPersonalLink ? "personal_links" : "availabilities"
    }/${token}/zoom_meetings`;
    const url = constructRequestURL(path);
    const param = createZoomUniqueLinkParam();

    const payloadData = {
      body: JSON.stringify(param),
    };

    const sentryContext = {
      token,
      url,
      payloadData,
      type: isPersonalLink
        ? "personal link - zoom"
        : "Schedule availability - zoom",
      action: isPersonalLink
        ? "onClickReschedulePersonalLink-zoom"
        : "onClickScheduleAvailability-zoom",
      user,
    };

    return {
      url,
      payloadData,
      sentryContext,
    };
  }

  async onClickSchedulePersonalLink() {
    const {
      stillAvailable,
      sortedDayArray,
      selectedDay,
      daySlots,
      free_busy,
    } = await this.isSelectedPersonalLinkTimeStillAvailabile();
    if (!stillAvailable) {
      this._isSubmitting = false;
      this.setState({
        isTimeNoLongerAvailableModalOpen: true,
        isSubmitting: false,
        sortedDayArray,
        selectedDay,
        daySlots,
        free_busy,
      });
      return;
    }

    const { url, payloadData, sentryContext } = this.getZoomURLAndPayload();

    const { conferencing, default_zoom_mode } = this.state;
    if (
      conferencing === BACKEND_ZOOM &&
      default_zoom_mode !== ZOOM_PERSONAL_LINK
    ) {
      // if user not logged in -> 401 error
      Fetcher.post(url, payloadData)
        .then((response) => {
          if (!this._isMounted) {
            return;
          }
          this.trackBooking("schedule_personal_link");

          if (IsEmptyObject(response) || !response.join_url) {
            // create with personal links
            this.bookPersonalLink();
            return;
          }

          const { join_url, id } = response;

          this.setState(
            {
              zoomLink: join_url,
              zoomId: id,
              uniqueZoomMeeting: response,
              zoomDescription: this.shouldCreateFullZoomDescription()
                ? createUniqueZoomDescription(response)
                : null,
            },
            this.bookPersonalLink
          );
        })
        .catch((error) => {
          handleError(error, sentryContext);
          if (!this._isMounted) {
            return;
          }

          this.bookPersonalLink();
        });
    } else {
      this.bookPersonalLink();
    }
  }

  bookPersonalLink() {
    /* We create a unique token for the meeting and pass it to description */
    /* We also create the appointment with that token on backend */
    /* link_token or appointment_token is the token of the token for the personal_link we connect to */
    const tokenToUse = !!this.state.token
      ? this.state.token
      : this.state.appointmentToken;
    const meetingToken = generateAvailabilityToken(tokenToUse);
    this._meetingToken = meetingToken;
    const eventData = this.constructPersonalLinkEventData({ meetingToken });
    const path = "personal_links/book";
    const params = {
      sendUpdates: "all",
      conferenceDataVersion: 1,
      link_type: BACKEND_PERSONAL,
      link_token: this.state.token,
      appointment_token: this.state.appointmentToken,
      meeting_token: meetingToken,
    };

    const queryParams = constructQueryParams(params);
    const url = `${constructRequestURL(path, isV2())}?${queryParams}`;

    const payloadData = { body: JSON.stringify(eventData) };

    this.submitBook(url, payloadData);
  }

  async onClickReschedule() {
    if (this._isSubmitting) {
      return;
    }

    // need both since _isSubmitting is instant and state is async. We need state to trigger the spinner
    this._isSubmitting = true;
    this.setState({ isSubmitting: true });

    // onClickReschedule handles reschedule for both personal and Slots
    if (this.props.isPersonalLink) {
      // personal link
      const {
        stillAvailable,
        sortedDayArray,
        selectedDay,
        daySlots,
        free_busy,
      } = await this.isSelectedPersonalLinkTimeStillAvailabile();
      if (!stillAvailable) {
        this._isSubmitting = false;
        this.setState({
          isTimeNoLongerAvailableModalOpen: true,
          isSubmitting: false,
          sortedDayArray,
          selectedDay,
          daySlots,
          free_busy,
        });
        return;
      }
    } else {
      // slots
      const {
        stillAvailable,
        sortedDayArray,
        selectedDay,
        daySlots,
      } = await this.isSelectedSlotStillAvailabile();
      if (!stillAvailable) {
        this._isSubmitting = false;
        this.setState({
          isTimeNoLongerAvailableModalOpen: true,
          isSubmitting: false,
          sortedDayArray,
          selectedDay,
          daySlots,
        });
        return;
      }
    }

    const eventData = this.constructReschedulePersonalLinkEventData();

    const path = `${getSlotsPath()}/${
      this.state.event.user_event_id
    }/reschedule`;
    const params = {
      sendUpdates: "all",
      conferenceDataVersion: 1,
    };

    const queryParams = constructQueryParams(params);
    const url = constructRequestURL(path, isV2()) + `?${queryParams}`;

    const payloadData = { body: JSON.stringify(eventData) };

    this.submitReschedule(url, payloadData);
  }

  constructReschedulePersonalLinkEventData() {
    const start = {
      dateTime: this.getDateTimeForBackend(this.state.selectedTime.start),
    };

    const end = {
      dateTime: this.getDateTimeForBackend(this.state.selectedTime.end),
    };

    let attendees = [
      {
        self: true,
        email: getUserEmail(this.state.user),
        organizer: true,
        responseStatus: ACCEPTED_STATUS,
      },
    ];
    const eventAttendees = getEventAttendees(this.state.event);
    if (eventAttendees?.length > 0) {
      eventAttendees.forEach((a) => {
        if (
          a?.email &&
          a?.email?.trim().toLowerCase() !== getUserEmail(this.state.user)
        ) {
          let updatedAttendee = _.clone(a);

          // update everyone's status to needs action
          updatedAttendee.responseStatus = NEEDS_ACTION_STATUS;
          attendees = attendees.concat(updatedAttendee);
        }
      });
    }

    const calendar_event = {
      event_start: start,
      event_end: end,
      start,
      end,
      attendees,
    };

    const event = isV2()
      ? { calendar_event }
      : { google_calendar_event: calendar_event };

    return {
      event,
      appointment_token: this.state.backendAppointmentToken,
      user_calendar_id: this.state.event.user_calendar_id,
      user_event_id: this.state.event.user_event_id,
      ...this.createEmailData(),
    };
  }

  determineInitialDataFetch() {
    if (this.props.isGroupVoteSpreadSheet) {
      this.fetchGroupVoteSpreadSheet();
      return;
    }
    if (this.props.isPersonalLink) {
      if (this.props.isReschedule) {
        this.reschedulePersonalLink();
      } else {
        this.fetchPersonalLink();
      }
    } else if (this.props.isGroupVoteLink) {
      this.fetchGroupVoteLink();
    } else {
      if (this.props.isReschedule) {
        this.rescheduleAvailabilityLink();
      } else {
        this.fetchAvailability();
      }
    }
  }

  createEventConferencingDescription() {
    const {
      zoomDescription,
      custom_conferencing_name,
      custom_conferencing_url,
      conferencing,
      zoomLink,
      phone_number,
      phone_region_code,
    } = this.state;

    if (zoomDescription) {
      return zoomDescription;
    }

    if (conferencing === BACKEND_ZOOM && zoomLink) {
      return `Join Zoom Meeting:<br>${zoomLink}`;
    }

    if (
      conferencing === BACKEND_CUSTOM_CONFERENCING &&
      custom_conferencing_name &&
      custom_conferencing_url
    ) {
      return `Join ${custom_conferencing_name}:<br>${custom_conferencing_url}`;
    }

    if (conferencing === BACKEND_PHONE && phone_number && phone_region_code) {
      return `Call ${this.getFirstName()} at ${formatPhoneNumberHumanReadable(
        phone_number,
        phone_region_code
      )}`;
    }

    if (conferencing === BACKEND_WHATS_APP) {
      return `Call ${this.getFirstName()} on WhatsApp (https://wa.me/${getPhoneNumberString(
        phone_number,
        phone_region_code
      )})`;
    }
  }

  createEventLocation() {
    const {
      location,
      custom_conferencing_name,
      custom_conferencing_url,
      conferencing,
      zoomLink,
      phone_number,
      phone_region_code,
    } = this.state;
    if (location) {
      return location;
    }

    if (conferencing === BACKEND_ZOOM && zoomLink) {
      return zoomLink;
    }

    if (
      conferencing === BACKEND_CUSTOM_CONFERENCING &&
      custom_conferencing_name &&
      custom_conferencing_url
    ) {
      return custom_conferencing_url;
    }

    if (conferencing === BACKEND_PHONE && phone_number && phone_region_code) {
      return formatRFCPhoneNumber(phone_number, phone_region_code);
    }

    if (conferencing === BACKEND_WHATS_APP) {
      return `https://wa.me/${getPhoneNumberString(
        phone_number,
        phone_region_code
      )}`;
    }
  }

  constructPersonalLinkEventData({ meetingToken }) {
    const {
      selectedTime,
    } = this.state;

    if (!selectedTime || !meetingToken) {
      return;
    }

    const start = {
      dateTime: this.getDateTimeForBackend(selectedTime.start),
    };

    const end = {
      dateTime: this.getDateTimeForBackend(selectedTime.end),
    };

    const summary = this.createPersonalLinkSummary();

    const conferencing = this.createConferencing();

    const attendees = this.createAttendees();

    const description = this.createDescription({ meetingToken });

    const calendar_event = {
      event_start: start,
      event_end: end,
      start,
      end,
      summary,
      title: summary,
      guestsCanModify: false,
      guestsCanInviteOthers: true,
      guestsCanSeeOtherGuests: true,
      conference_data: conferencing,
      attendees,
      description,
    };

    const eventLocation = this.createEventLocation();
    if (eventLocation) {
      calendar_event.location = eventLocation;
    }

    // Body: 2:30pm - Wednesday, June 10, 2020
    // Subject: 2:30pm Wed, Jun 10, 2020

    const event = isV2()
      ? { calendar_event }
      : { google_calendar_event: calendar_event };
    return {
      event,
      ...this.createEmailData(),
    };
  }

  createCustomQuestionDescription() {
    const { filteredQuestions } = this.state;
    if (isEmptyArray(this.state?.filteredQuestions)) {
      return "";
    }

    let customQuestionDescription = "";

    // We expect that the first two questions are name and email which are already displayed above
    // TODO: if we can guarantee elsewhere that name and email will always be the first two custom questions
    filteredQuestions.forEach((question, questionIndex) => {
      if (question?.answer) {
        customQuestionDescription += `${question.description.trim()}`;
        customQuestionDescription += "<br>";
        if (question.type === "phoneNumber" && question?.answer) {
          customQuestionDescription += `${this.createRegionCodeText(
            question?.regionCode || "US"
          )}`;
        }
        customQuestionDescription += `${
          question?.answer ? question.answer : ""
        }${"<br><br>"}`.trim();
      }
    });

    return customQuestionDescription;
  }

  createDescription({ meetingToken }) {
    const { allow_reschedule_and_cancel, appointmentToken, description, master_account } =
      this.state;
    const isEmptyString = isEmptyRichText(description);

    let updatedDescription = description || "";
    if (isEmptyString) {
      updatedDescription = ""; // so we don't have empty lines above content
    }

    if (!(updatedDescription === "" || updatedDescription.length === 0)) {
      // add new line if there's information there already
      updatedDescription = `${updatedDescription}<br><br>`;
    }

    const link = this.determineUrl();
    const rescheduleRoute = this.state.appointmentToken ? "r" : "rs";
    const cancelLink = `Cancel:<br>${link}/c/${meetingToken}`;
    const rescheduleLink = `Reschedule:<br>${link}/${rescheduleRoute}/${meetingToken}`;

    const conferencingDescription = this.createEventConferencingDescription();
    if (conferencingDescription) {
      updatedDescription += conferencingDescription + "<br><br><br>";
    }
    const customQuestions = this.createCustomQuestionDescription();
    if (customQuestions) {
      updatedDescription += `${customQuestions}<br><br>`;
    }

    if (appointmentToken || allow_reschedule_and_cancel) {
      updatedDescription += `Need to change this event?<br><br>${cancelLink}<br><br>${rescheduleLink}`;
    }

    if (!this.state.settings?.hide_default_signature) {
      const referralCode = getMasterAccountReferralCode(master_account);
      if (referralCode) {
        const url = `https://www.vimcal.com/?referral_code=${referralCode}`;
        updatedDescription += `<br><br>Created with <a href="${url}" rel="noopener noreferrer" target="_blank">Vimcal.com</a>`;
      } else {
        updatedDescription += `<br><br>${VIMCAL_RICH_TEXT_SIGNATURE}`;
      }
    }

    return updatedDescription;
  }

  shouldCreateFullZoomDescription() {
    const { provider, is_scheduling_for_exec } = this.state;
    return provider === OUTLOOK_PROVIDER || is_scheduling_for_exec;
  }

  async isSelectedSlotStillAvailabile() {
    try {
      const url = this.getSlotsUrl(); // Check to make sure availability link is not expired since time of booking
      const response = await Fetcher.get(url);
      if (!this._isMounted) {
        return;
      }
      const dayTimeSlots = this.constructDayAndTimeSlots(response);
      const {
        selectedTime,
      } = this.state;
      const {
        start,
        end,
      } = selectedTime;
      const { sortedDayArray, selectedDay, daySlots } = dayTimeSlots;
      const stillAvailable = Object.values(daySlots).some((daySlot) => {
        return daySlot.some(slot => {
          const {
            start: daySlotStart,
            end: daySlotEnd,
          } = slot;
          return start === daySlotStart && end === daySlotEnd; // string comparison
        });
      });
      return {
        stillAvailable,
        sortedDayArray,
        selectedDay,
        daySlots,
      };
    } catch (error) {
      const sentryContext = this.createSentryContext("isTimeStillAvailabile");
      handleError(error, sentryContext);
      return {stillAvailable: true};
    }
  }

  async isSelectedPersonalLinkTimeStillAvailabile() {
    try {
      const {
        url,
        payloadData,
      } = this.getPersonalLinkAvailabilityFetchData();
      const response = await Fetcher.post(url, payloadData);
      if (!this._isMounted) {
        return;
      }
      const {
        free_busy,
      } = response;
      const dayTimeSlots = this.createPersonalLinkPreviewData(response);
      if (!dayTimeSlots) {
        return {
          stillAvailable: false
        };
      }
      const {
        selectedTime,
      } = this.state;
      const {
        start,
        end,
      } = selectedTime;
      const {
        sortedDayArray,
        selectedDay,
        daySlots,
      } = dayTimeSlots;
      const stillAvailable = Object.values(daySlots).some((daySlot) => {
        return daySlot.some(slot => {
          const {
            start: daySlotStart,
            end: daySlotEnd,
          } = slot;
          return start === daySlotStart && end === daySlotEnd; // string comparison
        });
      });
      return {
        stillAvailable,
        sortedDayArray,
        selectedDay,
        daySlots,
        free_busy,
      }
    } catch(error) {
      const sentryContext = this.createSentryContext("isSelectedPersonalLinkTimeStillAvailabile");
      handleError(error, sentryContext);
      return {stillAvailable: true};
    }
  }

  async onClickScheduleAvailability() {
    const {
      stillAvailable,
      sortedDayArray,
      selectedDay,
      daySlots,
    } = await this.isSelectedSlotStillAvailabile();
    if (!stillAvailable) {
      this._isSubmitting = false;
      this.setState({
        isTimeNoLongerAvailableModalOpen: true,
        isSubmitting: false,
        sortedDayArray,
        selectedDay,
        daySlots,
      });
      return;
    }

    const { url, payloadData, sentryContext } = this.getZoomURLAndPayload();

    const { conferencing, default_zoom_mode } = this.state;

    if (
      conferencing === BACKEND_ZOOM &&
      default_zoom_mode !== ZOOM_PERSONAL_LINK
    ) {
      // only call backend to create zoom if it's zoom
      // if user not logged in -> 401 error
      Fetcher.post(url, payloadData)
        .then((response) => {
          if (!this._isMounted) {
            return;
          }
          this.trackBooking("schedule_availability");

          if (IsEmptyObject(response) || !response.join_url) {
            // create with personal links
            this.bookAvailabiity();
            return;
          }

          const { join_url, id } = response;

          // zoomId is used so you can join the meeting from the zoom app
          this.setState(
            {
              zoomLink: join_url,
              zoomId: id,
              uniqueZoomMeeting: response,
              zoomDescription: this.shouldCreateFullZoomDescription()
                ? createUniqueZoomDescription(response)
                : null,
            },
            this.bookAvailabiity
          );
        })
        .catch((error) => {
          handleError(error, sentryContext);
          if (!this._isMounted) {
            return;
          }

          this.bookAvailabiity();
        });
    } else {
      this.bookAvailabiity();
    }
  }

  checkNameEmailInputForError() {
    let newState = {};

    if (this.state.inputName.length === 0) {
      newState["nameHasIssue"] = true;
    } else {
      newState["nameHasIssue"] = false;
    }

    let trimmedEmail = this.state.inputEmail.trim();

    if (!IsEmailValid(trimmedEmail)) {
      newState["emailHasIssue"] = true;
    } else {
      newState["emailHasIssue"] = false;
    }

    const {
      filteredQuestions
    } = this.state;
    newState["customQuestionsHasIssue"] = false;
    if (!isEmptyArray(filteredQuestions)) {
      filteredQuestions.forEach((question, questionIndex) => {
        if (question?.required && !question?.answer) {
          newState["customQuestionsHasIssue"] = true;
        }
      });
    }

    if (
      newState["emailHasIssue"] ||
      newState["nameHasIssue"] ||
      newState["customQuestionsHasIssue"]
    ) {
      this.setState(newState);
      return true;
    } else {
      return false;
    }
  }

  getIndex() {
    if (typeof URL !== "function") {
      // unsafe to use the function, e.g. IE does not support URL function
      return null;
    }

    let url_string = window.location.href;

    if (!url_string) {
      return null;
    }

    let url = new URL(url_string);

    if (
      !(
        url &&
        url.searchParams &&
        url.searchParams.get &&
        url.searchParams.get("index")
      )
    ) {
      return null;
    }

    const index = url.searchParams.get("index");

    return parseInt(index);
  }

  trackEvent(action) {
    // firebase.analytics().logEvent(action, {
    //   category: 'availability_link',
    //   action,
    //   label: this.state.token || 'no_token',
    //   timeStamp,
    //   userId: '',
    //   isMobile: this.state.isMobile,
    //   url: window.location.href
    // });
  }

  onClickBackFromConfirmNameAndEmail() {
    this.setState({
      onClickedConfirmTime: false,
      nameHasIssue: false,
      emailHasIssue: false,
    });
  }

  onClickBackFromSelectTime() {
    this.setState({ shouldShowMobileSelectTime: false });
  }

  onChangeName(e) {
    if (
      e.target.value &&
      e.target.value.length === 1 &&
      !this.state.hasCapitalizedFirstLetter
    ) {
      this.setState({
        inputName: e.target.value.toUpperCase(),
        hasCapitalizedFirstLetter: true,
      });

      return;
    }

    this.setState({ inputName: e.target.value });
  }

  onChangeEmail(e) {
    this.setState({ inputEmail: e.target.value });
  }

  onClickDaySlot(day) {
    if (day === this.state.selectedDay && !this.state.isMobile) {
      return;
    }

    let newState = { selectedDay: day, selectedTime: null };

    if (this.state.isMobile) {
      newState["shouldShowMobileSelectTime"] = true;
    }

    this.setState(newState);

    this.trackEvent(`on_select_date_${day}`);
  }

  handleWindowSizeChange() {
    if (!this._isMounted) {
      return;
    }

    let displayWidth = window.innerWidth;

    if (this.props.isPersonalLink) {
      if (displayWidth < 700 && !this.state.isMobile) {
        this.setState({ isMobile: true });
      } else if (displayWidth > 700 && this.state.isMobile) {
        this.setState({ isMobile: false });
      }

      return;
    }

    if (displayWidth <= MOBILE_WIDTH_LIMIT && !this.state.isMobile) {
      this.setState({ isMobile: true });
    } else if (displayWidth > MOBILE_WIDTH_LIMIT && this.state.isMobile) {
      this.setState({ isMobile: false });
    }
  }

  onMouseLeave() {
    this.setState({ hoverDay: null });
  }

  onMouseEnterDaySlot(day) {
    this.setState({ hoverDay: day });
  }

  onChangeRequestEarlyAccessEmail(e) {
    this.setState({ inputEmail: e.target.value });
  }

  daySlotStyle(day) {
    if (this.state.selectedDay === day) {
      return { backgroundColor: "#EEF4FF", color: "#3980F8" };
    } else if (this.state.hoverDay === day && !this.state.isMobile) {
      return { backgroundColor: "#F6F7F7" };
    } else {
      return {};
    }
  }

  determineContent() {
    const { isMobile } = this.state;

    if (this.state.calendarsUnavailable) {
      return this.renderShowCalendarsUnavailable();
    }
    if (this.state.expired) {
      return this.renderShowExpired();
    } else if (this.state.notFound) {
      return this.renderNotFound();
    } else if (this.state.areAllSlotsBusy) {
      return this.renderAllSlotsBusyError();
    } else if (this.state.noAvailabilityError) {
      return this.renderNoAvailabilityError();
    } else if (this.state.error) {
      return this.renderShowError();
    } else if (this.state.hasEventPassed) {
      return this.renderOneLinerMessage(
        "Sorry, this event has already passed."
      );
    } else if (this.state.showSentConfirmationEmail) {
      if (this.state.isGroupVote) {
        return this.renderConfirmationGroupVote();
      }

      if (isMobile) {
        return this.renderMobileConfirmationEmail();
      }

      return this.renderConfirmationEmailWithLoginButtons();
    } else if (this.state.onClickedConfirmTime) {
      if (isMobile) {
        return this.renderMobileEnterNameEmailAndOtherInfo();
      }
      return this.renderEventInformationAndEnterNameAndEmail();
    } else if (isMobile && this.state.shouldShowMobileSelectTime) {
      return this.renderMobileSelectTime();
    } else if (this.state.isGroupVote) {
      if (this.isGroupVoteSpreadsheet()) {
        return this.renderGroupVoteSpreadSheetContainer();
      }
      return this.renderGroupVoteContainer();
    } else {
      if (isMobile) {
        return this.renderMobileSelectDayAndTimeModal();
      }
      return this.renderSelectDayAndTimeContainer();
    }
  }

  renderAllSlotsBusyError() {
    return <GeneralErrorMessage message={"All slots are booked."} />;
  }

  // on mobile, we don't show dropdown for select time zone
  renderMobileSelectTime() {
    return (
      <div className="w-full flex flex-col items-center overflow-y-auto">
        {this.renderBackButton(this.onClickBackFromSelectTime)}

        <div className="mt-6 font-size-20 font-weight-400">Select a Time</div>

        <div className="font-size-14 font-weight-300 mt-5">
          {this.state.duration} minutes
        </div>

        <div className="flex font-size-14 w-full justify-center items-center mt-1">
          <Globe size={14} className="mr-2.5" />
          {AddAbbrevationToTimeZone(this.getSelectedTimeZone())}
        </div>

        <ColoredLine style={{ marginTop: 34, width: "100%" }} />

        {this.renderMobileSwipeDate()}

        <div
          className="flex flex-col justify-between h-full w-full"
          style={{ height: "calc(100vh - 250px)" }}
        >
          <div className="w-full">{this.renderTimeSlots()}</div>
          <HoverableLogo containerClassName="mb-4 mt-4" />
        </div>
      </div>
    );
  }

  renderGroupVoteSpreadSheetContainer() {
    if (this.state.isMobile) {
      return this.renderGroupVoteContainer();
    }
    return (
      <GroupVoteSpreadSheet
        bookingLink={this.state.groupVoteLink}
        onClickSave={this.submitGroupVoteSpreadsheet}
        isMobile={this.state.isMobile}
        selectedTimeZone={this.getSelectedTimeZone()}
        isShowingConfirmPage={this.state.onClickedConfirmTime}
        timeZone={this.state.timeZone}
      >
        {this.renderEventInfo()}
      </GroupVoteSpreadSheet>
    );
  }

  renderGroupVoteContainer() {
    const onClickSave = (newAttendeeSlots) => {
      // only new slots with out name
      this.setState({ onClickedConfirmTime: true, newAttendeeSlots });
    };
    return (
      <div className="flex flex-col items-center w-full">
        {this.renderEventInfo()}
        <div
          className={Classnames("w-full", this.state.isMobile ? "" : "px-7")}
        >
          <GroupVotePreview
            bookingLink={this.state.groupVoteLink}
            setName={(inputName) => this.setState({ inputName })}
            onClickSaveAttendeeSlots={onClickSave}
            isMobile={this.state.isMobile}
            selectedTimeZone={this.getSelectedTimeZone()}
          />
        </div>
      </div>
    );
  }

  renderMobileSwipeDate() {
    const currentDayIndex = this.state.sortedDayArray.indexOf(
      this.state.selectedDay
    );

    return (
      <div className="display-flex-flex-direction-row my-6">
        {currentDayIndex > 0 ? (
          <div className="availability-back-button" onClick={this.goBackADay}>
            <ChevronLeft size={15} />
          </div>
        ) : (
          <div style={{ width: 25, height: 25 }}></div>
        )}

        <div
          className={classNames(
            "items-center flex flex-col select-none",
            this.state.sortedDayArray?.length === 1 ? "w-full" : "w-44"
          )}
        >
          <div className="font-size-16 font-weight-500">
            {moment(this.state.selectedDay).format("dddd")}
          </div>

          <div className="text-center secondary-text-color font-size-14 font-weight-300 mt-1">
            {moment(this.state.selectedDay).format("MMM D, YYYY")}
          </div>
        </div>

        {currentDayIndex < this.state.sortedDayArray.length - 1 ? (
          <div
            className="availability-back-button"
            onClick={this.goForwardADay}
          >
            <ChevronRight size={15} />
          </div>
        ) : (
          <div style={{ width: 25, height: 25 }}></div>
        )}
      </div>
    );
  }

  renderMobileEnterNameEmailAndOtherInfo() {
    return (
      <div className={"w-full overflow-y-auto relative overflow-x-hidden"}>
        <div
          className="availability-left-container position-relative"
          style={{
            border: "none",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            borderBottom: "1px solid #d7d9d6",
          }}
        >
          {this.renderBackButton(this.onClickBackFromConfirmNameAndEmail)}

          <AvailabilityLinkEventInfo
            info={this.state}
            alignItems={"center"}
            companyName={this.getCompanyQuestionAnswer()}
            selectedTimeZone={this.getSelectedTimeZone()}
            shouldShowTimeZoneDropdown={this.shouldShowTimeZoneDropdown()}
            onSelectTimeZone={this.onSelectTimeZone}
          />
        </div>

        {this.props.isReschedule
          ? this.renderConfirmReschedule()
          : this.renderEnterNameAndEmail()}

        {this.props.isReschedule ? null : this.renderSaveButton()}
        {this.renderTimeNoLongerAvailableModal()}
      </div>
    );
  }

  renderSaveButton() {
    const determineSaveButtonLabel = () => {
      if (this.state.isSubmitting) {
        return <Spinner useSmallSpinner={true} className="absolute -top-4" />;
      }

      return this.state.isGroupVote ? "Submit Vote" : "Schedule Event";
    };

    const { isMobile } = this.state;
    return (
      <div
        className={classNames(
          "mt-5 mb-10 sticky",
          "availability-schedule-event-button",
          "side-panel-modal-background-color",
          "select-none",
          isMobile ? "bottom-4 ml-5" : "bottom-0"
        )}
        style={{
          marginBottom: this.state.isMobile ? "0px" : "25px",
        }}
        onClick={this.onClickSchedule}
      >
        {determineSaveButtonLabel()}
      </div>
    );
  }

  renderEventInformationAndEnterNameAndEmail() {
    return (
      <div
        style={{ position: "relative" }}
        className={"width-100-percent display-flex-flex-direction-row"}
      >
        <div className="availability-left-container position-relative items-start">
          {this.renderBackButton(this.onClickBackFromConfirmNameAndEmail)}

          <div className="schedule-event-page-margin-top">
            {this.renderEventInfo("mt-2")}
          </div>
        </div>
        {this.renderTimeNoLongerAvailableModal()}

        {this.props.isReschedule
          ? this.renderConfirmReschedule()
          : this.renderEnterNameAndEmail()}
      </div>
    );
  }

  renderTimeNoLongerAvailableModal() {
    const {
      isTimeNoLongerAvailableModalOpen,
    } = this.state;

    const onRequestClose = () => {
      this.setState({ 
        isTimeNoLongerAvailableModalOpen: false,
        onClickedConfirmTime: false,
        selectedTime: null,
      });
    };

    return (
      <EventModalPopup
        isOpen={isTimeNoLongerAvailableModalOpen}
        onRequestClose={onRequestClose}
        title="Sorry, that time is no longer available"
        width="360px"
      >
        <div className="flex flex-col items-start gap-4">
          <div className="font-size-12">Please select a different time to book this event.</div>
          <div
            className={classNames(
              "mt-4",
              "availability-schedule-event-button",
              "side-panel-modal-background-color",
              "select-none",
            )}
            onClick={onRequestClose}
          >
            {"View Times"}
          </div>
        </div>
      </EventModalPopup>
    );
  }

  renderAddAttendees() {
    if (
      this.props.isReschedule ||
      this.props.isGroupVoteLink ||
      this.props.isGroupVoteSpreadSheet
    ) {
      return null;
    }

    if (!this.state.showExpandedAttendees) {
      return (
        <div
          className="cursor-pointer add-attendees-button duration-200 select-none w-max py-1 px-2 rounded-md my-6 font-size-14"
          onClick={() => this.setState({ showExpandedAttendees: true })}
        >
          Add Guests
        </div>
      );
    } else {
      return (
        <div>
          <div className="mt-4 mb-1 font-size-14">Guest Emails</div>

          <AdditionalGuestsInput
            onSetGuests={this.setAdditionalGuests}
            guestEmails={this.state.additionalGuests}
            guestInputText={this.state.guestInputText}
            setGuestInputText={this.setGuestInputText}
          />
          <div className="font-size-12 mt-1">
            Add up to 10 additional guests to the event
          </div>
        </div>
      );
    }
  }

  setAdditionalGuests(emails) {
    if (!emails) {
      return;
    }
    this.setState({ additionalGuests: emails });
  }

  determineContainerStyle() {
    if (
      this.state.error ||
      this.state.expired ||
      this.state.notFound ||
      this.state.areAllSlotsBusy ||
      this.state.hasEventPassed ||
      this.state.calendarsUnavailable
    ) {
      return { width: 500, height: 700 };
    } else if (this.shouldDisplayWelcomeInviteeBanner()) {
      return { borderRadius: "0px 0px 6px 6px" };
    } else {
      return {};
    }
  }

  createBookingLinkSummary() {
    const { title, inputName, user, filteredQuestions } = this.state;
    const companyName = this.getCompanyQuestionAnswer();
    // have to handle every question
    const updatedTitle = replaceVariableBlocksWithValue({
      title,
      inviteeName: inputName,
      companyName,
      questions: filteredQuestions,
    });
    if (updatedTitle) {
      return updatedTitle;
    }
    return `${this.getFullName() || getUserEmail(user)} <> ${inputName.trim()}`;
  }

  createPersonalLinkSummary() {
    return this.createBookingLinkSummary();
  }

  getDateTimeForBackend(timeString) {
    // timeString comes in as October 29, 2024 8:00 AM;
    const {
      selectedTimeZone
    } = this.state;
    if (!selectedTimeZone || selectedTimeZone === guessTimeZone()) {
      return moment(timeString).format();
    }
    const localFormatTime = moment(timeString).format();
    const jsDate = parseISO(localFormatTime);
    const timeInLocalTime = getTimeInAnchorTimeZone(jsDate, selectedTimeZone, guessTimeZone());
    return timeInLocalTime.toISOString();
  }

  constructEventData({ meetingToken }) {
    const {
      selectedTime,
    } = this.state;

    if (!selectedTime || !meetingToken) {
      return;
    }

    const start = {
      dateTime: this.getDateTimeForBackend(selectedTime.start),
    };

    const end = {
      dateTime: this.getDateTimeForBackend(selectedTime.end),
    };

    const summary = this.createBookingLinkSummary();
    const conferencing = this.createConferencing();
    const attendees = this.createAttendees();
    const description = this.createDescription({ meetingToken });

    const calendar_event = {
      event_start: start,
      event_end: end,
      start,
      end,
      summary,
      title: summary,
      guestsCanModify: false,
      guestsCanInviteOthers: true,
      guestsCanSeeOtherGuests: true,
      conference_data: conferencing,
      attendees,
      description,
    };

    const eventLocation = this.createEventLocation();
    if (eventLocation) {
      calendar_event.location = eventLocation;
    }

    // no need to add google or outlook link to location
    // phone gets handled by description

    // if (this.state.description) {
    //   // Without this first if-block the description containing custom questions would be overwritten
    //   // by a custom description provided in Mettings Details of Default Slots Settings
    //   if (calendar_event?.description) {
    //     calendar_event.description = `${this.state.description}\n${calendar_event.description}`;
    //   } else {
    //     calendar_event.description = this.state.description;
    //   }
    // }

    // Body: 2:30pm - Wednesday, June 10, 2020
    // Subject: 2:30pm Wed, Jun 10, 2020

    const event = isV2()
      ? { calendar_event }
      : { google_calendar_event: calendar_event };
    return {
      event,
      ...this.createEmailData(),
    };
  }

  createAttendees() {
    const {
      calendar_provider_id,
      google_calendar_id,
      user,
      sender_full_name,
      inputEmail,
      inputName,
      master_account,
    } = this.state;

    const defaultUserEmail = isV2() ? calendar_provider_id : google_calendar_id;

    let selfResponse = {
      self: true,
      email: defaultUserEmail ? defaultUserEmail.trim() : getUserEmail(user),
      organizer: true,
      responseStatus: ACCEPTED_STATUS,
    };

    if (defaultUserEmail) {
      // passed in new user
      if (sender_full_name) {
        selfResponse.displayName = sender_full_name.trim();
      }
    } else if (master_account?.full_name) {
      selfResponse.displayName = master_account.full_name.trim();
    } else if (user.full_name) {
      // default user
      selfResponse.displayName = user.full_name.trim();
    }

    const inviteeAttendee = {
      responseStatus: NEEDS_ACTION_STATUS,
      email: inputEmail.trim(),
      displayName: inputName.trim(),
    };
    let attendees;
    if (this.state.provider === OUTLOOK_PROVIDER) {
      // do notneed to put self for outlook
      attendees = [inviteeAttendee];
    } else {
      attendees = [selfResponse, inviteeAttendee];
    }

    const existingEmails = attendees.map((e) => e.email);

    this.getAdditionalGuests()
      .map((e) => e.trim().toLowerCase())
      .filter((email) => IsEmailValid(email) && !existingEmails.includes(email))
      .forEach((email) => {
        // you can use forEach here because it won't execute if filter return an empty array
        attendees = attendees.concat({
          responseStatus: NEEDS_ACTION_STATUS,
          email,
        });
      });

    if (this.state.attendees?.length > 0) {
      // add other attendees from invite
      let existingEmails = attendees.map((a) => a.email.trim().toLowerCase());
      this.state.attendees.forEach((a) => {
        if (!a?.email) {
          return;
        }
        const email = a.email.trim();

        if (existingEmails.includes(email.toLowerCase())) {
          // already exist
          return;
        }

        const newAttendee = {
          responseStatus: NEEDS_ACTION_STATUS,
          email: email,
        };
        if (a.name) {
          newAttendee.displayName = a.name.trim();
        }
        if (a.isOptional) {
          newAttendee.optional = true;
        }

        attendees = attendees.concat(newAttendee);
        existingEmails = existingEmails.concat(email.toLowerCase());
      });
    }

    return attendees;
  }

  createEmailData() {
    const timeZone = this.getSelectedTimeZone();
    if (this.props.isReschedule) {
      return {
        user_calendar_id: this.state.userCalendarId,
        duration: this.state.duration,
        conferencing: this.state.conferencing,
        host_start_time_subject: this.createStartTimeSubject(true),
        host_start_time_body: this.createStartTimeBody(true),
        host_time_zone_text: AddAbbrevationToTimeZone(
          this.state.host_time_zone
        ),
        old_host_start_time_body: this.createOldHostStartTimeBody(),
        invitee_time_zone: timeZone,
        ...(this.state.location && { location: this.state.location }),
      };
    } else {
      return {
        user_calendar_id: this.state.userCalendarId,
        invitee_email: this.state.inputEmail,
        invitee_name: this.state.inputName,
        duration: this.state.duration,
        conferencing: this.state.conferencing,
        invitee_time_zone_text: AddAbbrevationToTimeZone(timeZone),
        invitee_time_zone: timeZone,
        invitee_start_time_body: this.createStartTimeBody(false),
        invitee_start_time_subject: this.createStartTimeSubject(false),
        host_start_time_subject: this.createStartTimeSubject(true),
        host_start_time_body: this.createStartTimeBody(true),
        host_time_zone_text: AddAbbrevationToTimeZone(
          this.state.host_time_zone
        ),
        additional_guests: this.getAdditionalGuests(),
        ...(this.state.location && { location: this.state.location }),
      };
    }
  }

  getAdditionalGuests() {
    const { guestInputText, additionalGuests } = this.state;
    const formattedText = guestInputText.trim().toLowerCase();
    if (IsEmailValid(formattedText)) {
      return additionalGuests.concat(formattedText);
    }

    return additionalGuests;
  }

  createStartTimeBody(isHost) {
    let timeString = this.state.selectedTime.start;

    return format(
      convertToTimeZone(this.getJSDateFromString(timeString), {
        timeZone: isHost ? this.state.host_time_zone : this.getSelectedTimeZone(),
      }),
      "h:mmaaa - eeee, MMMM d, yyyy"
    );
  }

  createOldHostStartTimeBody() {
    const timeString = this.state.oldStartTime;

    return format(
      convertToTimeZone(this.getJSDateFromString(timeString), {
        timeZone: this.state.host_time_zone,
      }),
      "h:mmaaa - eeee, MMMM d, yyyy"
    );
  }

  getJSDateFromString(timeString) {
    const {
      selectedTimeZone
    } = this.state;
    if (!selectedTimeZone || selectedTimeZone === guessTimeZone()) {
      return moment(timeString).toDate();
    }
    const localFormatTime = moment(timeString).format();
    const jsDate = parseISO(localFormatTime);
    const timeInLocalTime = getTimeInAnchorTimeZone(jsDate, selectedTimeZone, guessTimeZone());
    return timeInLocalTime;
  }

  createStartTimeSubject(isHost) {
    const timeString = this.state.selectedTime.start;
    const jsDate = this.getJSDateFromString(timeString);
    const timeZone = isHost ? this.state.host_time_zone : this.getSelectedTimeZone();
    return format(
      convertToTimeZone(jsDate, {
        timeZone,
      }),
      "h:mmaaa EEE, MMM d, yyyy"
    ) + ` (${createAbbreviationForTimeZone(timeZone, jsDate)})`;
  }

  createConferencing() {
    if (
      this.state.conferencing === BACKEND_ZOOM &&
      this.state.uniqueZoomMeeting
    ) {
      return createUniqueZoom(this.state.uniqueZoomMeeting);
    } else if (
      this.state.conferencing === BACKEND_ZOOM &&
      this.state.zoomLink
    ) {
      let zoomEntryPoint = {
        entryPointType: "video",
        uri: this.state.zoomLink,
        label: this.state.zoomLink,
      };

      let conferenceSolution = {
        key: {
          type: "addOn",
        },
        name: "Zoom Meeting",
        iconUri: ZOOM_LOGO_URL,
      };

      let entryPoints = [zoomEntryPoint];

      let conferencing = { entryPoints, conferenceSolution };
      if (this.state.zoomId) {
        // zoomId is used so you can join the meeting from the zoom app
        conferencing.conferenceId = this.state.zoomId;
      }

      return conferencing;
    } else if (this.state.conferencing === BACKEND_HANGOUT) {
      const requestId = createUUID();

      return {
        createRequest: {
          requestId,
        },
      };
    } else if (
      this.state.conferencing === BACKEND_PHONE &&
      this.state.phone_number &&
      this.state.phone_region_code
    ) {
      let phoneEntryPoint = {
        entryPointType: "phone",
        label: formatRFCPhoneNumber(
          this.state.phone_number,
          this.state.phone_region_code
        ),
        regionCode: this.state.phone_region_code,
        uri: formatRFCPhoneNumber(
          this.state.phone_number,
          this.state.phone_region_code
        ),
      };

      let entryPoints = [phoneEntryPoint];

      let conferenceSolution = {
        key: {
          type: "addOn",
        },
        name: "phone call",
        iconUri: PHONE_MEETING_ICON,
      };

      let notes = null;

      const fullName = this.getFullName();
      if (fullName) {
        notes = `Call ${fullName} at ${formatPhoneNumberHumanReadable(
          this.state.phone_number,
          this.state.phone_region_code
        )}`;
      }

      return { entryPoints, conferenceSolution, notes };
    } else if (
      this.state.conferencing === BACKEND_WHATS_APP &&
      this.state.phone_number &&
      this.state.phone_region_code
    ) {
      let phoneEntryPoint = {
        entryPointType: "video",
        uri: `https://wa.me/${getPhoneNumberString(
          this.state.phone_number,
          this.state.phone_region_code
        )}`,
        label: `WhatsApp ${formatRFCPhoneNumber(
          this.state.phone_number,
          this.state.phone_region_code
        )}`,
      };

      let entryPoints = [phoneEntryPoint];

      const conferenceSolution = {
        key: {
          type: "addOn",
        },
        name: "WhatsApp Call",
        iconUri: WHATS_APP_ICON,
      };

      let notes = null;

      const fullName = this.getFullName();
      if (fullName) {
        notes = `Call ${fullName} on WhatsApp (${formatRFCPhoneNumber(
          this.state.phone_number,
          this.state.phone_region_code
        )})`;
      }

      return { entryPoints, conferenceSolution, notes };
    } else if (
      this.state.conferencing === BACKEND_CUSTOM_CONFERENCING &&
      this.state.custom_conferencing_url &&
      this.state.custom_conferencing_name
    ) {
      const conferencingName = this.state.custom_conferencing_name;
      const conferencingUrl = this.state.custom_conferencing_url;

      const customEntry = {
        entryPointType: "video",
        uri: conferencingUrl,
        label: conferencingName,
      };

      let entryPoints = [customEntry];

      let conferenceSolution = {
        key: {
          type: "addOn",
        },
        name: conferencingName,
      };

      return { entryPoints, conferenceSolution };
    } else if (isOutlookConferencingOption(this.state.conferencing)) {
      return this.state.conferencing;
    } else {
      return null;
    }
  }

  getFullName() {
    if (this.isSchedulingForExec()) {
      return this.state.user?.full_name || getUserEmail(this.state.user) || "";
    }

    return this.state.master_account?.full_name || this.state.user?.full_name;
  }

  isSchedulingForExec() {
    return this.state.is_scheduling_for_exec;
  }

  getFirstName() {
    return this.state.master_account?.first_name || this.state.user.first_name;
  }

  getLinkType() {
    if (this.props.isPersonalLink) {
      return "personal_link";
    } else if (this.state.isGroupVote) {
      return "group_vote";
    } else {
      return "slots";
    }
  }

  onClickRequestEarlyAccess() {
    this.trackEvent("on_request_early_access");

    const email = this.state.inputEmail;
    const name = this.state.inputName;

    const path = `${getSlotsPath()}/waitlist`;
    const url = constructRequestURL(path, isV2());
    const payloadData = {
      body: JSON.stringify({ email, full_name: name }),
    };

    return Fetcher.post(url, payloadData).then((response) => {
      if (!this._isMounted) {
        return;
      }
      this.trackBooking("clicked_request_early_access");

      this.setState({ shouldDisplayRequestAccessEmailSent: true });
    });
  }

  goBackADay() {
    let currentIndex = this.state.sortedDayArray.indexOf(
      this.state.selectedDay
    );

    this.setState({
      selectedDay: this.state.sortedDayArray[currentIndex - 1],
      selectedTime: null,
    });
  }

  goForwardADay() {
    let currentIndex = this.state.sortedDayArray.indexOf(
      this.state.selectedDay
    );

    this.setState({
      selectedDay: this.state.sortedDayArray[currentIndex + 1],
      selectedTime: null,
    });
  }

  onClickConfirmOnTime() {
    this.setState({ onClickedConfirmTime: true });
    this.trackEvent("on_click_confirm_time");
  }

  onClickTimeSlot(time) {
    this.setState({ selectedTime: time });

    this.trackEvent(`on_select_time_${time}`);
  }

  closeWarningMessage() {
    this.setState({ errorWarning: null });
  }

  displayErrorWarning(message) {
    this.trackEvent("clicked_on_expired_link");

    this.setState({ errorWarning: message });

    clearTimeout(this.disappearingErrorMessageTimer);

    this.disappearingErrorMessageTimer = setTimeout(() => {
      if (!this._isMounted) {
        return;
      }

      this.closeWarningMessage();
    }, 5 * SECOND_IN_MS);
  }

  shouldDisplayWelcomeInviteeBanner() {
    return !(
      this.isConditionalState() ||
      (this.state.isMobile && this.state.shouldShowMobileSelectTime) ||
      !this.state.invitee_full_name
    );
  }

  isConditionalState() {
    return (
      this.state.expired ||
      this.state.notFound ||
      this.state.areAllSlotsBusy ||
      this.state.error ||
      this.state.hasEventPassed ||
      this.state.showSentConfirmationEmail ||
      this.state.onClickedConfirmTime ||
      this.state.calendarsUnavailable
    );
  }

  determineLargeTitle() {
    const { title, invitee_full_name: inviteeName, duration } = this.state;
    if (title?.length > 0) {
      if (
        shouldReplaceInviteeNameInTitle({
          title,
          inviteeName: inviteeName,
        })
      ) {
        return replaceInviteeNameInTitle({
          title,
          inviteeName,
        });
      }
      if (!doesTitleIncludeVariableBlocks({ title })) {
        return title;
      }
      return `${duration} Minute Meeting`;
    }

    return `${duration} Minute Meeting`;
  }

  getCompanyQuestionAnswer() {
    return (
      this.state.filteredQuestions?.find((question) =>
        isCompanyQuestion(question)
      )?.answer ?? ""
    );
  }

  testMaestroLink() {
    const testArray = Array(10000)
      .fill()
      .map((x, i) => i);
    const link = testArray.map((a) => getMaestroPersonalOnboardingLinkToken());
    const counts = {};

    for (const num of link) {
      counts[num] = counts[num] ? counts[num] + 1 : 1;
    }
  }

  getToken(props) {
    if (props.isMaestroSignUp) {
      return getMaestroPersonalOnboardingLinkToken();
    } else if (props.isRegularOnboarding) {
      return getRegularOnboardingToken();
    } else if (props.isSapphire) {
      return getSapphireOnboardingToken();
    }

    return props.match.params.token;
  }

  trackBooking(where) {
    const label = `type:${this.getLinkType()} || token: ${
      this.state.token
    } || meetingToken: ${
      this._meetingToken ?? this.state.appointmentToken ?? "null"
    }`;
    trackEvent({
      category: `name_${this.state.inputName}`,
      action: where,
      label,
    });
  }

  getSlotsUrl() {
    const endpoint = this.makeRequestEndpoint();
    return constructRequestURL(getSlotsPath() + endpoint, isV2());
  }

  getGroupVoteSpreadSheetUrl() {
    const endpoint = this.makeRequestEndpoint();
    return constructRequestURL("group_spreadsheet_links" + endpoint, isV2());
  }

  getGroupVoteUrl() {
    const endpoint = this.makeRequestEndpoint();
    return constructRequestURL("group_vote_links" + endpoint, isV2());
  }

  getPersonalLinkUrl() {
    const endpoint = this.makeRequestEndpoint();
    return constructRequestURL("personal_links" + endpoint, isV2());
  }

  makeRequestEndpoint() {
    const { token } = this.state;
    const { hasSlug, match } = this.props;
    const { slug, username } = match.params;

    return hasSlug ? `/by-slug/${username}/${slug}` : `/${token}`;
  }

  setGuestInputText(value) {
    this.setState({ guestInputText: value });
  }

  getNow() {
    if (performance?.now) {
      return performance.now();
    }
  }

  trackLoadTime(fromWhere) {
    try {
      const now = this.getNow();
      if (!now || !this.state.initialLoadTime) {
        return;
      }

      const differenceInSec = (
        (now - this.state.initialLoadTime) /
        1000
      ).toFixed(2);
      trackEvent({
        category: `booking-link-load-time-${fromWhere}`,
        action: window?.location?.href ?? "failed to get URL",
        label: differenceInSec,
      });
    } catch (error) {
      handleError(error);
    }
  }

  getSelectedTimeZone() {
    return this.state.selectedTimeZone || guessTimeZone();
  }

  isGroupVoteSpreadsheet() {
    return this.props.isGroupVoteSpreadSheet;
  }

  onSelectTimeZone(timeZone) {
    if (!isValidTimeZone(timeZone)) {
      return;
    }
    this.setState({
      selectedTimeZone: timeZone,
      selectedTime: null,
      host_time_zone: timeZone,
    }, () => {
      this.recalculateDayAndTimeSlots();
    });
  }

  shouldShowTimeZoneDropdown() {
    const {
      showSentConfirmationEmail,
      onClickedConfirmTime,
    } = this.state;
    return (
      !showSentConfirmationEmail &&
      !onClickedConfirmTime
    );
  }
}

export default withRouter(AvailabilityLink);
